/* eslint-disable */
import * as React from "react";
import { DynamicForm } from "@common-payment-portal/lib";
import { eligibilityForm, sampleApplicationForm } from "./sampleForms";

import {
  GoADropdown,
  GoADropdownItem,
  GoAGrid,
  GoASpacer,
  GoATextArea,
} from "@abgov/react-components";

const substitutions = {};

const forms = [eligibilityForm, sampleApplicationForm];

export const FormTest = () => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(0);
  const [sub, setSub] = React.useState(JSON.stringify(substitutions, null, 4));

  const [template, setTemplate] = React.useState(
    JSON.stringify(forms[selectedTemplateId], null, 4),
  );

  const onSubmit = async (values) => {
    return { success: true, message: "This is done" };
  };

  const tryParse = (r) => {
    try {
      return JSON.parse(r);
    } catch {
      return {};
    }
  };

  return (
    <GoAGrid minChildWidth="100ch">
      <div>
        <h3>Template</h3>
        <GoADropdown
          value={selectedTemplateId}
          onChange={(v, t) => {
            setSelectedTemplateId(t);
            setTemplate(JSON.stringify(forms[t], null, 4));
          }}
        >
          <GoADropdownItem value="0" label="Eligibility" />
          <GoADropdownItem value="1" label="Sample Form" />
        </GoADropdown>

        <GoATextArea
          value={template}
          rows={50}
          name="template"
          onChange={(name, value) => {
            setTemplate(value);
          }}
        />
      </div>
      <GoASpacer></GoASpacer>
      <DynamicForm
        form={tryParse(template ?? {})}
        onSubmit={onSubmit}
        substitutions={tryParse(sub ?? {})}
      />
    </GoAGrid>
  );
};
