import { React, useState, useCallback, useEffect } from "react";
import { useFormik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  GoAContainer,
  GoAButton,
  GoAPageBlock,
  GoASpacer,
  GoAGrid,
  GoAFormItem,
  GoAInput,
  GoAInputTel,
  GoAInputText,
  GoADivider,
  GoADropdown,
  GoADropdownItem,
  GoABlock,
} from "@abgov/react-components";

import canada from "canada";
import DisplayContent from "../../../components/DisplayContent";
import DateOfBirthInput from "../../../components/DateOfBirthInput";
import {
  genderList,
  phoneRegExp,
  phoneExtRegExp,
  postalCodeRegExp,
  formatPhoneNumber,
  toTitleCase,
  formatPostalCode,
} from "../../../global/Utility";

const schema = Yup.object().shape({
  givenName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "RESOURCES@form.givenName.invalidError")
    .trim()
    .required("RESOURCES@form.givenName.error"),
  middleName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "RESOURCES@form.middleName.invalidError")
    .trim(),
  surname: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "RESOURCES@form.surname.invalidError")
    .trim()
    .required("RESOURCES@form.surname.error"),
  gender: Yup.string().required("RESOURCES@form.gender.error"),
  streetAddressLine1: Yup.string()
    .matches(/^[a-zA-Z0-9\s,\-#&\/. ']*$/, 'RESOURCES@form.streetAddressLine1.invalidError')
    .max(50, 'RESOURCES@form.streetAddressLine1.maxError')
    .trim()
    .required("RESOURCES@form.streetAddressLine1.error"),
  streetAddressLine2: Yup.string()
    .matches(/^[a-zA-Z0-9\s,\-#&\/. ']*$/, 'RESOURCES@form.streetAddressLine2.invalidError')
    .max(50, 'RESOURCES@form.streetAddressLine2.maxError'),
  locality: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "RESOURCES@form.locality.invalidError")
    .trim()
    .required("RESOURCES@form.locality.error"),

  province: Yup.string().required("RESOURCES@form.province.error"),
  postalCode: Yup.string()
    .required("RESOURCES@form.postalCode.error")
    .matches(postalCodeRegExp, "RESOURCES@form.postalCode.invalidError"),
  phone: Yup.string()
    .required("RESOURCES@form.phone.error")
    .matches(phoneRegExp, "RESOURCES@form.phone.invalidError")
    .min(10, "RESOURCES@form.phone.minError")
    .max(20, "RESOURCES@form.phone.maxError"),
  phoneExtension: Yup.string().matches(
    phoneExtRegExp,
    "RESOURCES@form.phoneExtension.invalidError"
  ),
  mobile: Yup.string()
    .matches(phoneRegExp, "RESOURCES@form.mobile.invalidError")
    .min(10, "RESOURCES@form.mobile.minError")
    .max(20, "RESOURCES@form.mobile.maxError"),

  dob: Yup.date()
    .max(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "RESOURCES@form.dob.invalidError"
    )

    .required("RESOURCES@form.dob.error"),
});

const BasicAccount = ({
  type,
  id,
  props,
  stepData,
  canEdit,
  defaults,
  applicationTemplate,
  applicationList,
  onNext = null,
  onPrevious = null,
  portal,
  applicationStatus,
  getResourceValue,
  user,
}) => {
  const {
    givenName,
    fullName,
    middleName,
    surname,
    email,
    streetAddressLine1,
    streetAddressLine2,
    locality,
    country,
    postalCode,
    province,
    gender,
    dob,
    phone,
    phoneExtension,
    mobile,
  } = user ?? {};

  console.log(user)

  const personalInfoForm = useFormik({
    initialValues: {
      givenName: stepData?.givenName ?? givenName ?? "",
      middleName: stepData?.middleName ?? middleName ?? "",
      surname: stepData?.surname ?? surname ?? "",
      dob: stepData?.dob ?? dob ?? null,
      gender: stepData?.gender ?? "",
      streetAddressLine1: stepData?.streetAddressLine1 ?? "",
      streetAddressLine2: stepData?.streetAddressLine2 ?? "",
      locality: stepData?.locality ?? "",
      province: stepData?.province ?? "",
      postalCode: stepData?.postalCode ?? "",
      phone: stepData?.phone ?? phone ?? "",
      phoneExtension: stepData?.phoneExtension ?? phoneExtension ?? "",
      mobile: stepData?.mobile ?? mobile ?? "",
      email: stepData?.email ?? email ?? "",
    },
    validationSchema: schema,
    validateOnBlur: "false",
    validateOnMount: "false",
    onSubmit: async (values) => {
      if (onNext) {
        console.log("onNext", values);
        onNext({ ...values });
      }
    },
  });

  useEffect(() => {
    if (personalInfoForm.values.phone) {
      const formattedValue = formatPhoneNumber(personalInfoForm.values.phone);

      personalInfoForm.setFieldValue("phone", formattedValue);
    }
    if (personalInfoForm.values.mobile) {
      const formattedValue = formatPhoneNumber(personalInfoForm.values.mobile);
      personalInfoForm.setFieldValue("mobile", formattedValue);
    }
  }, []);

  const handlePostalCodeChange = (name, value) => {
    const formattedValue = formatPostalCode(value);
    personalInfoForm.setFieldValue(name, formattedValue);
  };

  const handlePhoneChange = (name, value) => {
    const formattedValue = formatPhoneNumber(value);
    if (value.includes(formattedValue)) {
      personalInfoForm.setFieldValue(name, value);
    } else {
      personalInfoForm.setFieldValue(name, formattedValue);
    }   
  };

  const handleChange = (name, value, titleCase = false) => {
    personalInfoForm.setFieldValue(
      name,
      titleCase ? toTitleCase(value) : value
    );
  };

  return (
    <GoAPageBlock width="704px" data-testId="VerifiedAccount">
      <h2>
        {getResourceValue(
          "RESOURCES@personalInformation.title",
          "Confirm your information"
        )}
      </h2>

      <DisplayContent
        content={getResourceValue(
          "RESOURCES@personalInformation.description",
          "<p> Your information has been provided by Alberta.ca account. Please check and update your information before you proceed.</p>"
        )}
      />
      <GoAContainer accent="thin">
        <DisplayContent
          content={getResourceValue(
            "RESOURCES@personalInformation.interactiveContainer.title",
            "<h3>Personal information</h3>"
          )}
        />
        <GoAGrid minChildWidth="200px">
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.givenName.label",
              "First name"
            )}
            error={
              personalInfoForm.errors.givenName &&
              getResourceValue(personalInfoForm.errors.givenName)
            }
            id="givenName"
          >
            <GoAInputText
              name="givenName"
              maxLength="50"
              value={personalInfoForm.values.givenName}
              onChange={(v, t) => handleChange(v, t, true)}
            />
          </GoAFormItem>
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.middleName.label",
              "Middle name"
            )}
            requirement="optional"
            id="middleName"
            error={
              personalInfoForm.errors.middleName &&
              getResourceValue(personalInfoForm.errors.middleName)
            }
          >
            <GoAInputText
              name="middleName"
              value={personalInfoForm.values.middleName}
              onChange={(v, t) => handleChange(v, t, true)}
              maxLength={50}
            />
          </GoAFormItem>
        </GoAGrid>
        <GoASpacer vSpacing="l" />
        <GoAGrid minChildWidth="200px">
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.surname.label",
              "Last name"
            )}
            error={
              personalInfoForm.errors.surname &&
              getResourceValue(
                personalInfoForm.errors.surname,
                "Enter your last name"
              )
            }
            id="surname"
          >
            <GoAInputText
              name="surname"
              maxLength="50"
              value={personalInfoForm.values.surname}
              onChange={(v, t) => handleChange(v, t, true)}
            />
          </GoAFormItem>
        </GoAGrid>
        <GoASpacer vSpacing="l" />
        <GoADivider mt="l" mb="l"></GoADivider>
        <GoASpacer vSpacing="l" />

        <GoASpacer vSpacing="l" />
        <GoAGrid>
          <DateOfBirthInput
            form={personalInfoForm || {}}
            getResourceValue={getResourceValue}
          />

          <GoABlock>
            <div>
              <GoAFormItem
                label={getResourceValue("RESOURCES@form.gender.label", "Sex")}
                error={
                  personalInfoForm.errors.gender &&
                  getResourceValue(
                    personalInfoForm.errors.gender,
                    "Select the Sex"
                  )
                }
                id="gender"
              >
                <GoADropdown
                  name="gender"
                  filterable
                  placeholder={getResourceValue(
                    "RESOURCES@global.select.placeholder",
                    "—Select—"
                  )}
                  value={personalInfoForm.values.gender}
                  onChange={handleChange}
                >
                  {(genderList ?? []).map(({ key, label }) => {
                    return (
                      <GoADropdownItem key={key} value={key} label={label} />
                    );
                  })}
                </GoADropdown>
              </GoAFormItem>
            </div>
          </GoABlock>
        </GoAGrid>
      </GoAContainer>

      <GoAContainer accent="thin">
        <DisplayContent
          content={getResourceValue(
            "RESOURCES@personalInformation.contactInformation.title",
            "<h3>Contact information</h3>"
          )}
        />
        <GoAGrid minChildWidth="200px">
          <GoAFormItem
            width="100"
            label={getResourceValue(
              "RESOURCES@form.email.label",
              "Email address"
            )}
            id="email"
          >
            <GoAInput
              name="email"
              readonly="true"
              value={email}
              disabled="true"
            />
          </GoAFormItem>
        </GoAGrid>
        <hr></hr>

        <GoAGrid gap="s">
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.phone.label",
              "Phone number"
            )}
            helpText={getResourceValue(
              "RESOURCES@form.phone.help_text",
              "Add a + to enter an international number"
            )}
            error={
              personalInfoForm.errors.phone &&
              getResourceValue(
                personalInfoForm.errors.phone,
                "Enter a phone number"
              )
            }
            id="phone"
          >
            <GoAInputTel
              name="phone"
              value={personalInfoForm.values.phone}
              onChange={handlePhoneChange}
            />
          </GoAFormItem>
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.phoneExtension.label",
              "Ext."
            )}
            requirement="optional"
            error={
              personalInfoForm.errors.phoneExtension &&
              getResourceValue(
                personalInfoForm.errors.phoneExtension,
                "Enter a valid phone extension."
              )
            }
            id="phExt"
          >
            <GoAInputText
              name="phoneExtension"
              value={personalInfoForm.values.phoneExtension}
              onChange={handleChange}
            />
          </GoAFormItem>
        </GoAGrid>
        <GoASpacer vSpacing="l" />
        <GoAGrid gap="s">
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.mobile.label",
              "Mobile number"
            )}
            requirement="optional"
            error={
              personalInfoForm.errors.mobile &&
              getResourceValue(personalInfoForm.errors.mobile)
            }
            id="mobile"
          >
            <GoAInputTel
              name="mobile"
              value={personalInfoForm.values.mobile}
              onChange={handlePhoneChange}
            />
          </GoAFormItem>
        </GoAGrid>
        <GoASpacer vSpacing="l" />
        <GoADivider mt="l" mb="l"></GoADivider>

        <DisplayContent
          content={getResourceValue(
            "RESOURCES@personalInformation.primaryAddress.title",
            "<h3>Primary address</h3>"
          )}
        />

        <GoAGrid minChildWidth="200px">
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.streetAddressLine1.label",
              "Address line 1"
            )}
            error={
              personalInfoForm.errors.streetAddressLine1 &&
              getResourceValue(
                personalInfoForm.errors.streetAddressLine1,
                "Enter an address"
              )
            }
            id="streetAddressLine1"
          >
            <GoAInputText
              name="streetAddressLine1"
              maxLength="50"
              value={personalInfoForm.values.streetAddressLine1}
              onChange={(v, t) => handleChange(v, t, true)}
            />
          </GoAFormItem>
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.streetAddressLine2.label",
              "Address line 2"
            )}
            requirement="optional"
            error={
              personalInfoForm.errors.streetAddressLine2 &&
              getResourceValue(
                personalInfoForm.errors.streetAddressLine2,
                "Enter an address"
              )
            }
            id="streetAddressLine2"
          >
            <GoAInputText
              name="streetAddressLine2"
              value={personalInfoForm.values.streetAddressLine2}
              onChange={(v, t) => handleChange(v, t, true)}
            />
          </GoAFormItem>
        </GoAGrid>
        <GoASpacer vSpacing="l" />
        <GoAGrid minChildWidth="200px">
          <GoAFormItem
            label={getResourceValue("RESOURCES@form.locality.label", "City")}
            error={
              personalInfoForm.errors.locality &&
              getResourceValue(personalInfoForm.errors.locality, "Enter a city")
            }
            id="locality"
          >
            <GoAInputText
              name="locality"
              maxLength="40"
              value={personalInfoForm.values.locality}
              onChange={(v, t) => handleChange(v, t, true)}
            />
          </GoAFormItem>
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.province.label",
              "Province"
            )}
            error={
              personalInfoForm.errors.province &&
              getResourceValue(
                personalInfoForm.errors.province,
                "Select a province"
              )
            }
            id="province"
          >
            <GoADropdown
              name="province"
              filterable
              placeholder={getResourceValue(
                "RESOURCES@global.select.placeholder",
                "—Select—"
              )}
              value={personalInfoForm.values.province}
              onChange={handleChange}
              width="290px"
              arialabelledby="province"
              required="true"
            >
              {Object.keys(canada.provinces).map((provinceCode) => {
                return (
                  <GoADropdownItem
                    key={provinceCode}
                    value={provinceCode}
                    label={toTitleCase(canada.provinces[provinceCode])}
                  />
                );
              })}
            </GoADropdown>
          </GoAFormItem>
        </GoAGrid>
        <GoASpacer vSpacing="l" />
        <GoAGrid minChildWidth="200px">
          <GoAFormItem
            width="100"
            label={getResourceValue(
              "RESOURCES@form.postalCode.label",
              "Postal code"
            )}
            error={
              personalInfoForm.errors.postalCode &&
              getResourceValue(
                personalInfoForm.errors.postalCode,
                "Enter a postal code"
              )
            }
            id="postalCode"
          >
            <GoAInputText
              name="postalCode"
              value={personalInfoForm.values.postalCode}
              onChange={handlePostalCodeChange}
              maxLength={7}
            />
          </GoAFormItem>
        </GoAGrid>
      </GoAContainer>

      <GoAButton
        type="primary"
        testId="next-btn"
        onClick={personalInfoForm.handleSubmit}
        trailingIcon="arrow-forward"
      >
        {getResourceValue("RESOURCES@global.button.next", "Next")}
      </GoAButton>

      <GoASpacer vSpacing="l" />

      <Link to=""> Save and finish later</Link>

      <GoASpacer vSpacing="l" />
    </GoAPageBlock>
  );
};

export default BasicAccount;
