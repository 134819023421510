import React from "react";
import { GoAFileUploadInput, GoAFormItem } from "@abgov/react-components";

export const DynamicFormFile = ({ field, form }) => {
  const { label, name } = field;

  return (
    <GoAFormItem label={label} error={form?.errors?.[name]} name={name}>
      <GoAFileUploadInput />
    </GoAFormItem>
  );
};
