import { useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GoAPageBlock,
  GoAGrid,
  GoAContainer,
  GoAFormItem,
  GoAInputText,
  GoACheckbox,
  GoADropdown,
  GoADropdownItem,
  GoAInputTel,
  GoASpacer,
  GoAButtonGroup,
  GoAButton,
} from "@abgov/react-components";
import canada from 'canada';
import { phoneRegExp, phoneExtRegExp, postalCodeRegExp, formatPhoneNumber, toTitleCase, formatPostalCode } from '../../global/Utility'

const validateCityForProvince = (province, city) => {
    const validCity = !province || canada.cities.some(pair => {
        return pair[0] === city.toUpperCase() && pair[1] === province.toUpperCase();
    });

    return validCity;
};

const schema = Yup.object().shape({
    streetAddressLine1: Yup.string()
        .matches(/^[a-zA-Z0-9\s,\-#&\/. ']*$/, 'RESOURCES@form.streetAddressLine1.invalidError')
        .max(50, 'RESOURCES@form.streetAddressLine1.maxError')
        .trim()
        .required('RESOURCES@form.streetAddressLine1.error'),
    streetAddressLine2: Yup.string()
        .matches(/^[a-zA-Z0-9\s,\-#&\/. ']*$/, 'RESOURCES@form.streetAddressLine2.invalidError')
        .max(50, 'RESOURCES@form.streetAddressLine2.maxError'),
    locality: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'RESOURCES@form.locality.invalidError')
        .max(40, 'RESOURCES@form.locality.maxError')
        .trim()
        .required('RESOURCES@form.locality.error'),
        // .test('valid-city', 'RESOURCES@tempContactInfo.form.city.invalidError', (value, context) => {
        //     return validateCityForProvince(context.parent.province, value);
        // }),
    province: Yup.string().required('RESOURCES@form.province.error'),
    postalCode: Yup.string()
        .required("RESOURCES@form.postalCode.error")
        .matches(postalCodeRegExp, "RESOURCES@form.postalCode.invalidError"),
    phone: Yup.string()
        .required("RESOURCES@form.phone.error")
        .matches(phoneRegExp, "RESOURCES@form.phone.invalidError")
        .min(10, "RESOURCES@form.phone.minError")
        .max(20, "RESOURCES@form.phone.maxError"),
    phoneExtension: Yup.string()
        .matches(phoneExtRegExp, 'RESOURCES@form.phoneExtension.invalidError'),
    mobile: Yup.string()
        .matches(phoneRegExp, "RESOURCES@form.mobile.invalidError")
        .min(10, "RESOURCES@form.mobile.minError")
        .max(20, "RESOURCES@form.mobile.maxError")
});

const TemporaryContactInfo = ({
    id,
    props,
    stepData,
    canEdit,
    defaults,
    applicationTemplate,
    applicationList,
    getResourceValue,
    portal,
    onNext = null,
    onPrevious = null,
    saveStepData = null,
    applicationStatus,
    getStepData}) => {
    
    const personalStepData = getStepData('PersonalInformation');

    const [usePersonalInfo, setUsePersonalInfo] = useState(stepData?.usePersonalInfo ?? false);

    const temporaryContactInfoForm = useFormik({
        initialValues: {
            streetAddressLine1: stepData?.streetAddressLine1 ?? '',
            streetAddressLine2: stepData?.streetAddressLine2 ?? '',
            locality: stepData?.locality ?? '',
            province: stepData?.province ?? '',
            postalCode: stepData?.postalCode ?? '',
            phone: stepData?.phone ?? '',
            phoneExtension: stepData?.phoneExtension ?? '',
            mobile: stepData?.mobile ?? '',
       },
        validationSchema: schema,
        onSubmit: async (values) => {
            if ( onNext) {
                onNext({ ...values, usePersonalInfo });
            }
            //TODO: Save data to the context
        }
    });

    const handlePostalCodeChange = (name, value) => {
        const formattedValue = formatPostalCode(value);
        temporaryContactInfoForm.setFieldValue(name, formattedValue);
    };

    const handlePhoneChange = (name, value) => {
        const formattedValue = formatPhoneNumber(value);
        if (value.includes(formattedValue)) {
            temporaryContactInfoForm.setFieldValue(name, value);
        } else {
            temporaryContactInfoForm.setFieldValue(name, formattedValue);
        }   
    };

    const handleChange = (name, value, titleCase = false) => {
        temporaryContactInfoForm.setFieldValue(name, titleCase ? toTitleCase(value) : value);
    };

    const handlePrevious = useCallback(async () => {
        if ( saveStepData) {
            await saveStepData(temporaryContactInfoForm.values)
        }
        
        if ( onPrevious) {
            onPrevious();
        }
    }, [temporaryContactInfoForm]);

    const usePersonalInfoData = (_, value) => {
        setUsePersonalInfo(value);
        if (value === true) {
            const countryProvinceArr = personalStepData.province?.split('-');
            temporaryContactInfoForm.setFieldValue('streetAddressLine1', personalStepData.streetAddressLine1);
            temporaryContactInfoForm.setFieldValue('streetAddressLine2', personalStepData.streetAddressLine2);
            temporaryContactInfoForm.setFieldValue('locality', personalStepData.locality);
            temporaryContactInfoForm.setFieldValue('province', countryProvinceArr?.length === 2 ? countryProvinceArr[1] : personalStepData.province);
            temporaryContactInfoForm.setFieldValue('postalCode', personalStepData.postalCode);
            temporaryContactInfoForm.setFieldValue('phone', personalStepData.phone);
            temporaryContactInfoForm.setFieldValue('phoneExtension', personalStepData.phoneExtension);
            temporaryContactInfoForm.setFieldValue('mobile', personalStepData.mobile);
            temporaryContactInfoForm.validateForm(personalStepData);
        } else {
            temporaryContactInfoForm.setFieldValue('streetAddressLine1', '');
            temporaryContactInfoForm.setFieldValue('streetAddressLine2', '');
            temporaryContactInfoForm.setFieldValue('locality', '');
            temporaryContactInfoForm.setFieldValue('province', '');
            temporaryContactInfoForm.setFieldValue('postalCode', '');
            temporaryContactInfoForm.setFieldValue('phone', '');
            temporaryContactInfoForm.setFieldValue('phoneExtension', '');
            temporaryContactInfoForm.setFieldValue('mobile', '');
        }
    };

    return (
        <GoAPageBlock width="704px">
            <h2>{getResourceValue('RESOURCES@tempContactInfo.header.title','Add temporary contact information')}</h2>
            <p>
                {getResourceValue('RESOURCES@tempContactInfo.header.subtitle',
                `This information will be used to contact you, if necessary, while you're away from your primary home.
                If you have already returned home, use your personal address and phone number.`
                )}
            </p>
            <GoASpacer vSpacing="l" />
            <GoAContainer accent="thin" type="interactive">
                <h3>{getResourceValue('RESOURCES@tempContactInfo.form.title','Temporary contact information')}</h3>
                {false && <GoACheckbox name="usePersonalInfo" text={getResourceValue('RESOURCES@tempContactInfo.usePersonalInfo.label','Use my personal address and phone number')} onChange={usePersonalInfoData} checked={stepData?.usePersonalInfo}></GoACheckbox>}
                <GoAGrid minChildWidth="200px">
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.streetAddressLine1.label','Address line 1')}
                        error={temporaryContactInfoForm.errors.streetAddressLine1 && getResourceValue(temporaryContactInfoForm.errors.streetAddressLine1)}
                        id="addressLine1"
                    >
                        <GoAInputText
                            name="streetAddressLine1"
                            maxLength="50"
                            disabled={usePersonalInfo}
                            value={temporaryContactInfoForm.values.streetAddressLine1}
                            onChange={(v, t) => handleChange(v ,t, true)}
                        />
                    </GoAFormItem>
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.streetAddressLine2.label','Address line 2')}
                        requirement="optional"
                        error={temporaryContactInfoForm.errors.streetAddressLine2 && getResourceValue(temporaryContactInfoForm.errors.streetAddressLine2)}
                        id="streetAddressLine2"
                    >
                        <GoAInputText
                            name="streetAddressLine2"
                            maxLength="50"
                            disabled={usePersonalInfo}
                            value={temporaryContactInfoForm.values.streetAddressLine2}
                            onChange={(v, t) => handleChange(v ,t, true)}
                        />
                    </GoAFormItem>
                </GoAGrid>
                <GoASpacer vSpacing="l" />
                <GoAGrid minChildWidth="200px">
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.locality.label','City')}
                        error={temporaryContactInfoForm.errors.locality && getResourceValue(temporaryContactInfoForm.errors.locality)}
                        id="locality"
                    >
                        <GoAInputText
                            name="locality"
                            disabled={usePersonalInfo}
                            maxLength="40"
                            value={temporaryContactInfoForm.values.locality}
                            onChange={(v, t) => handleChange(v ,t, true)}
                        />
                    </GoAFormItem>
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.province.label','Province')}
                        error={temporaryContactInfoForm.errors.province && getResourceValue(temporaryContactInfoForm.errors.province, "Enter the province")}
                        id="province"
                    >
                        <GoADropdown name="province" 
                            filterable placeholder={getResourceValue(
                                "RESOURCES@global.select.placeholder",
                                "—Select—"
                            )} 
                            disabled={usePersonalInfo && temporaryContactInfoForm.values.province}
                            value={temporaryContactInfoForm.values.province} 
                            onChange={handleChange}
                            width="290px" 
                            arialabelledby="province">
                            {Object.keys(canada.provinces).map(provinceCode => {
                                return <GoADropdownItem key={provinceCode} value={provinceCode} label={toTitleCase(canada.provinces[provinceCode])} />
                            })}
                        </GoADropdown>
                    </GoAFormItem>
                </GoAGrid>
                <GoASpacer vSpacing="l" />
                <GoAGrid minChildWidth="200px">
                    <GoAFormItem width="100"
                        label={getResourceValue('RESOURCES@form.postalCode.label','Postal code')}
                        error={temporaryContactInfoForm.errors.postalCode && getResourceValue(temporaryContactInfoForm.errors.postalCode, "Enter the postal code")}
                        id="postalCode"
                    >
                        <GoAInputText
                            name="postalCode"
                            disabled={usePersonalInfo}
                            value={temporaryContactInfoForm.values.postalCode}
                            onChange={handlePostalCodeChange}
                            maxLength={7}
                        />
                    </GoAFormItem>
                </GoAGrid>

                <hr></hr>

                <GoAGrid minChildWidth="200px">
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.phone.label','Phone number')}
                        helpText={temporaryContactInfoForm.errors.phone === 'RESOURCES@form.phone.invalidError' ? getResourceValue('RESOURCES@form.phone.help_text', "Add a + to enter an international number") : ''}
                        error={temporaryContactInfoForm.errors.phone && getResourceValue(temporaryContactInfoForm.errors.phone)}
                        id="phone"
                    >
                        <GoAInputTel
                            name="phone"
                            disabled={usePersonalInfo}
                            value={temporaryContactInfoForm.values.phone}
                            onChange={handlePhoneChange}
                        />
                    </GoAFormItem>
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.phoneExtension.label','Ext.')}
                        requirement="optional"
                        error={temporaryContactInfoForm.errors.phoneExtension && getResourceValue(temporaryContactInfoForm.errors.phoneExtension, "Enter a valid phone extension.")}
                        id="phoneExtension"
                    >
                        <GoAInputText
                            name="phoneExtension"
                            width="50%"
                            disabled={usePersonalInfo}
                            value={temporaryContactInfoForm.values.phoneExtension}
                            onChange={handleChange}
                        />
                    </GoAFormItem>
                </GoAGrid>
                <GoASpacer vSpacing="l" />
                <GoAGrid minChildWidth="200px">
                    <GoAFormItem
                        label={getResourceValue('RESOURCES@form.mobile.label','Mobile number')}
                        helpText={temporaryContactInfoForm.errors.mobile === 'RESOURCES@form.mobile.invalidError' ? getResourceValue('RESOURCES@form.mobile.help_text', "Add a + to enter an international number") : ''}
                        requirement="optional"
                        error={temporaryContactInfoForm.errors.mobile && getResourceValue(temporaryContactInfoForm.errors.mobile, "Enter a valid mobile number. Mobile number must be at least 10 digits.")}
                        id="mobile"
                    >
                        <GoAInputTel
                            name="mobile"
                            disabled={usePersonalInfo}
                            value={temporaryContactInfoForm.values.mobile}
                            onChange={handlePhoneChange}
                        />
                    </GoAFormItem>
                </GoAGrid>

            </GoAContainer>

            <GoAButtonGroup>
                <GoAButton
                    type="tertiary"
                    leadingIcon="arrow-back"
                    onClick={handlePrevious}
                >
                    {getResourceValue('RESOURCES@global.button.previous','Previous')}
                </GoAButton>

                <GoAButton
                    type="primary"
                    trailingIcon="arrow-forward"
                    testId="next-btn"
                    onClick={temporaryContactInfoForm.handleSubmit}
                >
                    {getResourceValue('RESOURCES@global.button.next','Next')}
                </GoAButton>
            </GoAButtonGroup>

            <GoASpacer vSpacing="l" />

        </GoAPageBlock>
    );  
}

const TemporaryContactInfoRenderer = (props) => {
    return <TemporaryContactInfo {...props} />;
}

export default TemporaryContactInfoRenderer;