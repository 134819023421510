import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GoAPageBlock,
  GoAContainer,
  GoAFormItem,
  GoARadioGroup,
  GoARadioItem,
  GoADatePicker,
  GoADetails,
  GoASpacer,
  GoACallout,
  GoAButtonGroup,
  GoAButton,
} from "@abgov/react-components";
import DisplayContent from '../../components/DisplayContent'
import { useCallback } from "react";

const schema = Yup.object().shape({
    forced_to_leave: Yup.string().required('RESOURCES@forced_to_leave.question.error'),
    expect_to_evacuate: Yup.string().required('RESOURCES@expect_to_evacuate.question.error'),
    eligibilityDate: Yup.date().required('RESOURCES@eligibility.date.error'),
});

const EvacuationEligibility = ({
    id,
    props,
    stepData,
    canEdit,
    defaults,
    applicationTemplate,
    applicationList,
    getResourceValue,
    portal,
    onNext = null,
    onPrevious = null,
    saveStepData = null,
    applicationStatus,}) => {

    // const ELIGIBILITY_DATA = 'eligibilityData';
    // const eligibilityStoredData = sessionStorage.getItem(ELIGIBILITY_DATA);

    let eligibilityData = stepData;
    // if (!eligibilityData || Object.keys(eligibilityData).length === 0) {
    //     eligibilityData = eligibilityStoredData ? JSON.parse(eligibilityStoredData) : {};
    // }
 
    const eligibilityForm = useFormik({
        initialValues: {
            forced_to_leave: eligibilityData?.forced_to_leave ?? '',
            expect_to_evacuate: eligibilityData?.expect_to_evacuate ?? '',
            eligibilityDate: eligibilityData?.eligibilityDate ? new Date(eligibilityData?.eligibilityDate) : new Date()
        },
        validationSchema: schema,
        validateOnChange: false, // Disable validation on change
        validateOnBlur: true, // Enable validation on blur
        onSubmit: async (values) => {
            if ( onNext) {
                // sessionStorage.setItem(ELIGIBILITY_DATA, JSON.stringify(values));
                onNext({ ...values });
            }
            //TODO: Save data to the context
        },
    });

    const handleChange = (v, t) => {
        eligibilityForm.setFieldValue(v, t);
    };

    const isEligible = useCallback(() => {
        return eligibilityForm.isValid && eligibilityForm.values.forced_to_leave === 'Y' && 
                eligibilityForm.values.expect_to_evacuate === 'Y'   
    }, [eligibilityForm])

    const isNotEligible = useCallback(() => {
        return eligibilityForm.isValid && (eligibilityForm.values.forced_to_leave === 'N' || 
                eligibilityForm.values.expect_to_evacuate === 'N')   
    }, [eligibilityForm])


    const handlePrevious = useCallback(async () => {
        if ( saveStepData) {
            await saveStepData(eligibilityForm.values)
        }
        
        if ( onPrevious) {
            onPrevious();
        }
    }, [eligibilityForm]);
  
    return (
        <GoAPageBlock width="704px">
            <h2>{getResourceValue('RESOURCES@eligibility.header.title','Eligibility Check')}</h2>

            <GoAContainer accent="thin" type="non-interactive" padding="compact" mt="xs"  mb="xs">
                <DisplayContent content={getResourceValue('RESOURCES@eligibility.criteria.description', 
                `<h5>Eligibility Criteria</h5>
                You may qualify for the evacuation payment if you were not forced to leave your primary residence due to
                <ul>
                    <li>A mandatory evacuation order in effect for 7 days <strong>OR</strong></li>
                    <li>A Band Council Resolution in effect for 7 days</li>
                </ul>`
          )}
        />
      </GoAContainer>

      <h3>Check your eligibilty to apply</h3>
      <p>
        Answer the questions below to confirm that you are eligible to apply for
        a one time payment
      </p>

      <GoASpacer vSpacing="l" />

            <GoAFormItem
                label={getResourceValue('RESOURCES@eligibility.forced_to_leave.question','Were you forced to leave your primary residence in an affected area due to a mandatory evacuation order or Band Council Resolution?')}
                requirement="required"
                error={eligibilityForm.errors.forced_to_leave && getResourceValue(eligibilityForm.errors.forced_to_leave, "Select an answer")}
                id="forced_to_leave"
            >
                <GoARadioGroup name="forced_to_leave" disabled={!canEdit} value={eligibilityForm.values.forced_to_leave} onChange={handleChange}>
                    <GoARadioItem label="Yes" value="Y" />
                    <GoARadioItem label="No" value="N" />
                </GoARadioGroup>
            </GoAFormItem>

      <GoASpacer vSpacing="l" />

            <GoAFormItem
                label={getResourceValue('RESOURCES@eligibility.expect_to_evacuate.question','Have you been, or do you expect to be, evacuated from your primary residence for 7 days or more?')}
                requirement="required"
                error={eligibilityForm.errors.expect_to_evacuate && getResourceValue(eligibilityForm.errors.expect_to_evacuate, "Select an answer")}
                id="expect_to_evacuate"
            >
                <GoARadioGroup name="expect_to_evacuate" disabled={!canEdit} value={eligibilityForm.values.expect_to_evacuate} onChange={handleChange}>
                    <GoARadioItem label="Yes" value="Y" />
                    <GoARadioItem label="No" value="N" />
                </GoARadioGroup>
            </GoAFormItem>

      <GoASpacer vSpacing="l" />

            <GoAFormItem
                label={getResourceValue('RESOURCES@eligibility.eligibilityDate.label','Sample question with date selection')}
                requirement="required"
                error={eligibilityForm.errors.eligibilityDate && getResourceValue(eligibilityForm.errors.eligibilityDate, "Select a date")}
                id="eligibilityDate"
            >
                <GoADatePicker
                    name="eligibilityDate"
                    disabled={!canEdit}
                    value={eligibilityForm.values.eligibilityDate}
                    max={new Date()}
                    onChange={handleChange}
                />
            </GoAFormItem>

      <GoASpacer vSpacing="l" />

            {isEligible() && <GoACallout type="success" size="medium">
                            {getResourceValue('RESOURCES@eligibility.eligible.text','You are eligible to apply for the evacuation payment.')}                           
                        </GoACallout>}

            {isNotEligible() && <>
                            <GoACallout type="emergency" size="medium">
                                {getResourceValue('RESOURCES@eligibility.not_eligible.text','You are not eligible to apply for the evacuation payment.')}
                            </GoACallout>
                            <GoADetails  open={true} heading={getResourceValue('RESOURCES@eligibility.not_eligible_reasons.label','Reasons for ineligibility')}>
                            <DisplayContent content={getResourceValue('RESOURCES@eligibility.not_eligible_reasons.description', 
                                `You are not eligible if you:
                                <ul>
                                    <li>were living in community that was not affected by a mandatory evacuation order or Brand Council Resolution;</li>
                                    <li>were not evacuated from your primary residence for 7 days or more;</li>
                                    <li>were not in affected area during the evacuation period and had not planned to return during the evacuation period;</li>
                                    <li>were evacuated from the supportive living faculty such as a senior lodge, nursing home, extended care facility or temporary shelter and your evacuation was arranged by the facility and the facility continued to support during the evacuation and through your return; OR</li>
                                    <li>were evacuated from a work camp or employer-funded accommodation and your evacuation was arranged by your employer and your employer continued to support you during the evacuation and through your return.</li>
                                </ul>`
                )}/>
            </GoADetails>
          </>}

      <GoASpacer vSpacing="l" />

            <GoAButtonGroup>
                <GoAButton
                    type="primary"
                    testId="next-btn"
                    disabled={eligibilityForm.values.forced_to_leave !== 'Y' || eligibilityForm.values.expect_to_evacuate !== 'Y' || !eligibilityForm.values.eligibilityDate}
                    onClick={eligibilityForm.handleSubmit}
                >
                    {getResourceValue('RESOURCES@global.button.continue','Continue')}
                </GoAButton>

                <GoAButton
                    type="secondary"
                    onClick={handlePrevious}
                >
                    {getResourceValue('RESOURCES@eligibility.back_button.text','Back to Overview')}
                </GoAButton>
            </GoAButtonGroup>

            <GoASpacer vSpacing="l" />

        </GoAPageBlock>
    );
  };

const EvacuationEligibilityRenderer = (props) => {
    return <EvacuationEligibility {...props} />;
}

export default EvacuationEligibilityRenderer;