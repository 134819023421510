import { executePost } from "@common-payment-portal/common/apiUtils";

const endpoint = process.env.REACT_APP_API_ENDPOINT ?? '';

const loginEndpoint = `${endpoint}/api/auth/login` ?? '/api/auth/login';
const logoutEndpoint = `${endpoint}/api/auth/logout` ?? '/api/auth/logout';

export const getLoginEndPoint = (callBackUrl) => {
  return `${loginEndpoint}?callback=${callBackUrl}`;
}

export const getCurrentUrl = () => {
  return window.location.href;
}

export const getLoggedOutEndpoint = () => {
  return window.location.href.split('?')[0].split('/').slice(0, -1).join('/') + '/loggedOut';
}

export const getLogoutEndPoint = (callBackUrl) => {
  return `${logoutEndpoint}?callback=${callBackUrl}`;
}

export const checkSession = async () => {
  return await executePost("/api/auth/checkSession");
};