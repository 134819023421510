
const VerificationStatuses = {
    Unknown: 0,
    Basic: 1,
    Pending: 2,
    Verified: 3,
}

module.exports = {
    VerificationStatuses
}