import { globalComponentList, portalComponentList } from "./rendererList";

export const getRenderer = ({ id, scope }) => {
  if (scope === "global") {
    return globalComponentList.find((x) => x.id === id)?.component;
  } else {
    return portalComponentList.find((x) => x.id === id)?.component;
  }
};

export const today = new Date();

export const yesterday = ((d) => new Date(d.setDate(d.getDate() - 1)))(
  new Date()
);

export const getDateObj = (date) => {
  if (date === null || date === undefined || date === "") {
    return null;
  }

  try {
    return new Date(date);
  } catch (e) {
    return null;
  }
};