import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GoAGrid,
  GoAContainer,
  GoAFormItem,
  GoAInputText,
  GoADropdown,
  GoADropdownItem,
  GoARadioGroup,
  GoARadioItem,
  GoASpacer,
  GoADivider,
  GoADetails,
  GoAButtonGroup,
  GoAButton,
  GoABlock,
} from "@abgov/react-components";
import DateOfBirthInput from "../../../components/DateOfBirthInput";
import FirstNationStatusDetail from "../../../components/FirstNationStatusDetail"
import { genderList, toTitleCase } from "../../../global/Utility";

const documentTypeList = [
  { key: 1, label: "No documents available" },
  { key: 2, label: "Alberta Health Number" },
  { key: 3, label: "Birth Certificate" },
  { key: 4, label: "Passport" },
  { key: 5, label: "Alberta ID Card/Drivers Licence" },
];

const schema = Yup.object().shape({
    givenName: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'RESOURCES@familyMemberForm.givenName.invalidError')
        .max(50, 'RESOURCES@familyMemberForm.givenName.maxError')
        .trim()
        .required('RESOURCES@familyMemberForm.givenName.error'),
    surname: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'RESOURCES@familyMemberForm.surname.invalidError')
        .max(50, 'RESOURCES@familyMemberForm.surname.maxError')
        .trim()
        .required('RESOURCES@familyMemberForm.surname.error'),
    dob: Yup.date()
        .max(new Date(), "RESOURCES@familyMemberForm.dob.error")
        .required("RESOURCES@familyMemberForm.dob.error"),
    gender: Yup.string().required('RESOURCES@familyMemberForm.gender.error'),
    documentType: Yup.string().required('RESOURCES@familyMemberForm.documentType.error'),
    documentNumber: Yup.string()
        .matches(/^[a-zA-Z0-9\s-]*$/, 'RESOURCES@familyMemberForm.documentNumber.invalidError')
        .max(16, 'RESOURCES@familyMemberForm.documentNumber.maxError')
        .when('documentType', {
            is: (documentType) => !!documentType && documentType !== "1",
            then: () => Yup.string()
                .trim()
                .matches(/^[a-zA-Z0-9\s-]*$/, 'RESOURCES@familyMemberForm.documentNumber.invalidError')
                .max(16, 'RESOURCES@familyMemberForm.documentNumber.maxError')
                .required('RESOURCES@familyMemberForm.documentNumber.error')
        }),
    isIndigenous: Yup.string().required('RESOURCES@global.error.selectAnswer'),
    band: Yup.string()
        .when('isIndigenous', {
            is: (isIndigenous) => isIndigenous === 'Y',
            then: () => Yup.string()
                .matches(/^\d{3}$/, 'RESOURCES@global.error.invalidLength')
        }),
    family: Yup.string()
        .when('isIndigenous', {
            is: (isIndigenous) => isIndigenous === 'Y',
            then: () => Yup.string()
                .matches(/^\d{5}$/, 'RESOURCES@global.error.invalidLength')
        }),
    position: Yup.string()
        .when('isIndigenous', {
            is: (isIndigenous) => isIndigenous === 'Y',
            then: () => Yup.string()
                .matches(/^\d{2}$/, 'RESOURCES@global.error.invalidLength')
        }),
    FNIHB: Yup.string()
        .when('isIndigenous', {
            is: (isIndigenous) => isIndigenous === 'Y',
            then: () => Yup.string()
                .matches(/^\d{8}$/, 'RESOURCES@familyMemberForm.FNIHB.invalidError')
        }),
    livesOnReserve: Yup.string()
        .when('isIndigenous', {
            is: (isIndigenous) => isIndigenous === 'Y',
            then: () => Yup.string()
                .required('RESOURCES@global.error.selectAnswer')
        }) 
});

const FamilyMember = ({
  familyMember,
  setShowDeleteFamilyMember,
  addUpdateFamilyMember,
  getResourceValue,
}) => {
  const familyMemberForm = useFormik({
    initialValues: {
      givenName: familyMember?.givenName ?? "",
      surname: familyMember?.surname ?? "",
      dob: familyMember?.dob ?? null,
      gender: familyMember?.gender ?? "",
      documentType: familyMember?.documentType ?? "",
      documentNumber: familyMember?.documentNumber ?? "",
      isIndigenous: familyMember?.isIndigenous ?? "",
      band: familyMember?.band,
      family: familyMember?.family,
      position: familyMember?.position,
      FNIHB: familyMember?.FNIHB,
      livesOnReserve: familyMember?.livesOnReserve,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      addUpdateFamilyMember({ ...values, id: familyMember?.id });
    },
  });

  const handleChange = (name, value, titleCase = false) => {
    familyMemberForm.setFieldValue(
      name,
      titleCase ? toTitleCase(value) : value
    );
  };

    return (
    <GoAContainer accent="thin" type="interactive">
            <h3>{Object.keys(familyMember??{})?.length > 0 ? getResourceValue('RESOURCES@familyMemberForm.editFamilyMember','Edit Family Member') : getResourceValue('RESOURCES@familyMemberForm.addFamilyMember','Add Family Member')}</h3>

      <GoAGrid minChildWidth="30ch">
        <GoAFormItem
          label={getResourceValue(
            "RESOURCES@familyMemberForm.givenName.label",
            "First name"
          )}
          error={
            familyMemberForm.errors.givenName &&
            getResourceValue(familyMemberForm.errors.givenName)
          }
          id="givenName"
        >
          <GoAInputText
            name="givenName"
            maxLength="50"
            value={familyMemberForm.values.givenName}
            onChange={(v, t) => handleChange(v, t, true)}
          />
        </GoAFormItem>
        <GoAFormItem
          label={getResourceValue(
            "RESOURCES@familyMemberForm.surname.label",
            "Last name"
          )}
          error={
            familyMemberForm.errors.surname &&
            getResourceValue(familyMemberForm.errors.surname)
          }
          id="surname"
        >
          <GoAInputText
            name="surname"
            value={familyMemberForm.values.surname}
            onChange={(v, t) => handleChange(v, t, true)}
          />
        </GoAFormItem>
      </GoAGrid>

      <GoASpacer vSpacing="l" />

      <GoAGrid minChildWidth="30ch">
        <DateOfBirthInput
          form={familyMemberForm}
          getResourceValue={getResourceValue}
        />
        <GoABlock>
          <div></div>
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@familyMemberForm.gender.label",
              "Sex"
            )}
            error={
              familyMemberForm.errors.gender &&
              getResourceValue(familyMemberForm.errors.gender, "Select the Sex")
            }
            id="gender"
          >
            <GoADropdown
              name="gender"
              placeholder={getResourceValue(
                "RESOURCES@global.select.placeholder",
                "—Select—"
              )}
              value={familyMemberForm.values.gender}
              onChange={handleChange}
              arialabelledby="gender"
            >
              {(genderList ?? []).map(({ key, label }) => {
                return <GoADropdownItem key={key} value={key} label={label} />;
              })}
            </GoADropdown>
          </GoAFormItem>
        </GoABlock>
      </GoAGrid>

      <GoASpacer vSpacing="l" />

      <GoAGrid minChildWidth="30ch">
        <GoAFormItem
          label={getResourceValue(
            "RESOURCES@familyMemberForm.documentType.label",
            "Identification document type"
          )}
          error={
            familyMemberForm.errors.documentType &&
            getResourceValue(
              familyMemberForm.errors.documentType,
              "Select a document type. Select “No documents available” if you don’t have a document."
            )
          }
          id="documentType"
        >
          <GoADropdown
            name="documentType"
            placeholder={getResourceValue(
              "RESOURCES@global.select.placeholder",
              "—Select—"
            )}
            value={familyMemberForm.values.documentType}
            onChange={handleChange}
            arialabelledby="documentType"
            width="300px"
          >
            {(documentTypeList ?? []).map(({ key, label }) => {
              return <GoADropdownItem key={key} value={key} label={label} />;
            })}
          </GoADropdown>
        </GoAFormItem>
        <GoAFormItem
          label={getResourceValue(
            "RESOURCES@familyMemberForm.documentNumber.label",
            "Identification document number"
          )}
          error={
            familyMemberForm.errors.documentNumber &&
            getResourceValue(familyMemberForm.errors.documentNumber)
          }
          id="documentNumber"
        >
          <GoAInputText
            name="documentNumber"
            value={familyMemberForm.values.documentNumber}
            onChange={handleChange}
          />
        </GoAFormItem>
      </GoAGrid>

      <GoASpacer vSpacing="l" />

      <GoAFormItem
        label={getResourceValue(
          "RESOURCES@familyMemberForm.isIndigenous.question",
          "Does this family member identify as First Nations, Inuit, or Métis?"
        )}
        error={
          familyMemberForm.errors.isIndigenous &&
          getResourceValue(
            familyMemberForm.errors.isIndigenous,
            "Select an answer"
          )
        }
        id="isIndigenous"
      >
        <GoARadioGroup
          name="isIndigenous"
          value={familyMemberForm.values.isIndigenous}
          onChange={handleChange}
        >
          <GoARadioItem label="Yes" value="Y" />
          <GoARadioItem label="No" value="N" />
        </GoARadioGroup>
      </GoAFormItem>

      {familyMemberForm.values.isIndigenous === "Y" && (
        <div>
          <GoADivider mt="s" mb="s"></GoADivider>

          <div>
              <FirstNationStatusDetail
                form={familyMemberForm}
                getResourceValue={getResourceValue}
                pageResourceKey='familyMember'
              />
          </div>

          <GoADivider mt="l" mb="l"></GoADivider>

          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@familyMemberForm.livesOnReserve.question",
              "Do this family member live on reserve?"
            )}
            error={
              familyMemberForm.errors.livesOnReserve &&
              getResourceValue(
                familyMemberForm.errors.livesOnReserve,
                "Select an answer"
              )
            }
            id="livesOnReserve"
          >
            <GoARadioGroup
              name="livesOnReserve"
              value={familyMemberForm.values.livesOnReserve}
              onChange={handleChange}
            >
              <GoARadioItem label="Yes" value="Y" />
              <GoARadioItem label="No" value="N" />
            </GoARadioGroup>
          </GoAFormItem>
        </div>
      )}

      <GoASpacer vSpacing="l" />

      <GoAButtonGroup>
        {Object.keys(familyMember ?? {})?.length > 0 && (
          <GoAButton
            type="primary"
            onClick={familyMemberForm.handleSubmit}
            testId="save-btn"
          >
            {getResourceValue("RESOURCES@global.button.save", "Save")}
          </GoAButton>
        )}

        {!Object.keys(familyMember ?? {})?.length && (
          <GoAButton
            type="primary"
            leadingIcon="add"
            onClick={familyMemberForm.handleSubmit}
            testId="add-btn"
          >
            {getResourceValue("RESOURCES@global.button.add", "Add")}
          </GoAButton>
        )}

        <GoAButton type="tertiary" onClick={addUpdateFamilyMember}>
          {getResourceValue("RESOURCES@global.button.cancel", "Cancel")}
        </GoAButton>

        {Object.keys(familyMember ?? {})?.length > 0 && (
          <GoAButton
            type="tertiary"
            onClick={() => {
              setShowDeleteFamilyMember(familyMember);
            }}
          >
            {getResourceValue("RESOURCES@global.button.delete", "Delete")}
          </GoAButton>
        )}
      </GoAButtonGroup>
    </GoAContainer>
  );
};

export default FamilyMember;
