import {
  GoAFormItem,
  GoADropdown,
  GoADropdownItem,
} from "@abgov/react-components";

const FormDropDown = ({
  label,
  name,
  id,
  value,
  options,
  requirement,
  error,
  handleChange,
}) => {
  const ddlOptions = options.map(({ value, name }, index) => {
    return (
      <GoADropdownItem key={index} name={name} value={value} label={name} />
    );
  });
  return (
    <GoAFormItem label={label} requirement={requirement} error={error} id={id}>
      <GoADropdown
        name={name}
        ariaLabelledBy={id}
        error={false}
        value={value}
        onChange={handleChange}
      >
        <GoADropdownItem name={name} value="" label="Select..." />
        {ddlOptions}
      </GoADropdown>
    </GoAFormItem>
  );
};

export default FormDropDown;
