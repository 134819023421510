import React from "react";
import { DynamicFormLayout } from "./DynamicFormLayout";
import { useDynamicForm } from "./hooks/useDynamicForm";

export const DynamicForm = ({ form, onSubmit, data, substitutions }) => {
  const {
    defaultValues,
    title,
    description,
    formSettings,
    fields,
  } = useDynamicForm({
    form,
    data,
    substitutions,
  });


  return (
    <DynamicFormLayout
      title={title}
      description={description}
      fields={fields}
      formSettings={formSettings}
      data={defaultValues}
      form={form}
      onSubmit={onSubmit}
    />
  );
};
