export const eligibilityForm = {
  title: "Eligibility",
  description: `You may qualify for the evacuation payment if you were forced to leave your primary residence due to:
    <ul>
    <li>a mandatory evacuation order in effect for 7 days; or</li>
    <li>a Band Council Resolution in effect for 7 days.</li>
    </ul>`,
  templateFields: [],
  formSettings: {
    submitEnabled: true,
    submitButtonLabel: "Submit",
  },
  fields: [
    {
      type: "select",
      subType: "radio",
      label:
        "Were you forced to leave your primary residence in an affected area due to a mandatory evacuation order or Band Council Resolution?",
      name: "forcedToLeave",
      options: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      validations: {
        required: true,
      },
    },
    {
      type: "select",
      subType: "radio",
      label:
        "Have you been, or do you expect to be, evacuated from your primary residence for 7 days or more?",
      name: "evacuatedFromPrimaryResidence",
      options: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      validations: {
        required: true,
      },
    },
  ],
};

export const sampleApplicationForm = {
  title: "Sample Form Title",
  description: "",
  version: "1.0",
  templateFields: ["NAME", "EMAIL", "DISCLAMER"],
  formSettings: {
    submitEnabled: true,
    submitButtonLabel: "Submit",
  },
  fields: [
    {
      type: "paragraph",
      subType: "paragraph",
      name: "description",
      label: "This is a paragraph",
    },
    {
      type: "paragraph",
      subType: "paragraph",
      name: "description2",
      label: "This is a <strong>paragraph</stronh> <i>with</i> <u>html</u>",
    },
    {
      type: "input",
      subType: "name",
      label: "Name",
      maxLength: 256,
      validations: {
        required: true,
        maxLength: 256,
      },
      name: "name",
    },
    {
      type: "input",
      subType: "date",
      label: "Date",
      validations: {
        required: true,
      },
      name: "date",
    },
    {
      type: "file",
      label: "Email",
      maxLength: 120,
      validations: {
        required: true,
      },
      subType: "document_image",
      name: "email",
    },
    {
      type: "check",
      label: "Remember Me",
      validations: {
        required: true,
      },
      name: "rememberMe",
      defaultValue: true,
    },
    {
      type: "select",
      subType: "radio",
      label: "Select One",
      name: "selectOne",
      options: [
        {
          label: "Option 1",
          value: "Option1",
        },
        {
          label: "Option 2",
          value: "Option2",
        },
        {
          label: "Option 3",
          value: "Option3",
        },
        {
          label: "Option 4",
          value: "Option4",
        },
      ],
      validations: {
        required: true,
      },
    },
    {
      type: "select",
      subType: "singleSelect",
      label: "Select One",
      name: "selectOne",
      options: [
        {
          label: "Option 1",
          value: "Option1",
        },
        {
          label: "Option 2",
          value: "Option2",
        },
        {
          label: "Option 3",
          value: "Option3",
        },
        {
          label: "Option 4",
          value: "Option4",
        },
      ],
      validations: {
        required: true,
      },
    },
  ],
};
