import { useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { 
    GoAPageBlock, 
    GoASpacer,
    GoAFormItem,
    GoACheckbox, 
    GoAButton 
} from "@abgov/react-components";
import DisplayContent from "../components/DisplayContent";

const schema = Yup.object().shape({
    acceptTermOfUse: Yup.bool().oneOf([true], 'RESOURCES@termsOfUse.acceptError').required('RESOURCES@termsOfUse.acceptError')
});

const TermsOfUse = ({
    id,
    props,
    stepData,
    canEdit,
    defaults,
    applicationTemplate,
    applicationList,
    getResourceValue,
    portal,
    onNext = null,
    onPrevious = null,
    saveStepData = null,
    applicationStatus,}) => {

    const TERMS_OF_USE_DATA = 'termsOfUseData';    

    // const termsOfUseStoredData = sessionStorage.getItem(TERMS_OF_USE_DATA);
    
    let { acceptTermOfUse } = stepData ?? {};
    // if (!acceptTermOfUse) {
    //     acceptTermOfUse = termsOfUseStoredData ? (JSON.parse(termsOfUseStoredData))?.acceptTermOfUse : false;
    // }
 
    // skip the page if acceptTermOfUse was accepted
    if (acceptTermOfUse) {
        onNext();
    }

    const termsOfUseForm = useFormik({
        initialValues: {
            acceptTermOfUse: acceptTermOfUse ?? false,
        },
        validationSchema: schema,
        validateOnChange:false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            if(onNext) {
                // sessionStorage.setItem(TERMS_OF_USE_DATA, JSON.stringify(values));
                await onNext({ acceptTermOfUse: values.acceptTermOfUse });
            }
        },
    });
   
    const handleChange = (v, t) => {
        termsOfUseForm.setFieldValue(v, t);
    };

    const capitalize = (str) => {
        if (str?.length > 0) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        return str;
    }

    const domain = portal?.domainKey ?? '';

    return (
        <GoAPageBlock width="704px">
            <h2>{getResourceValue('RESOURCES@termsOfUse.title','Terms of Use')}</h2>

            <DisplayContent content={getResourceValue('RESOURCES@termsOfUse.content',
            `<p>You are about to apply for an {param1} payment from the Goverment of Alberta.</p>

            <p>The personal information collected through this portal is for the purpose of administering the MyAlberta {param2} Payment Program. This collection is authorized by section 33(c) of the Freedom of Information and Protection of Privacy Act. For questiona about the collection of personal information, contact <a target="_blank" href="https://www.alberta.ca/alberta-supports#:~:text=For%20all%20other%20enquiries%20about,Edmonton%20780%2D644%2D9992.">Alberta Support Contact Centre (ASCC)</a>ddd<a href="#" onclick='window.open("http://www.foracure.org.au");return false;'>aaa</a></p>

            <p>You are responsible for ensuring that your application information is complete and accurate. Any false, inaccurate, or misleading information will be considered a breach of this Terms of Use and may result in a criminal investigation and/or prosecutable offense.</p>

            <p>You must accept these Terms of Use to complete the application for this {param3} payment from the Government of Alberta.</p>`,
            { "domain": domain, "cDomain": capitalize(domain) })} />

            <GoASpacer vSpacing="l" />

            <GoAFormItem
                label={getResourceValue('RESOURCES@termsOfUse.acceptTermOfUse.label','I accept the Terms of Use')}
                requirement="required"
                error={termsOfUseForm.errors.acceptTermOfUse && getResourceValue(termsOfUseForm.errors.acceptTermOfUse, "Accept the Term of Use to continue")}
                id="acceptTermOfUse"
            >
                <GoACheckbox name="acceptTermOfUse" text={getResourceValue('RESOURCES@termsOfUse.acceptTermOfUse.label', 'I accept the Terms of Use')} onChange={handleChange}></GoACheckbox>
            </GoAFormItem>

            <GoASpacer vSpacing="l" />

            <GoAButton
                    type="primary"
                    testId="next-btn"
                    onClick={termsOfUseForm.handleSubmit}
                >
                    {getResourceValue('RESOURCES@global.button.continue','Continue')}
            </GoAButton>

            <GoASpacer vSpacing="l" />

        </GoAPageBlock>
    );
};



const TermsOfUseRenderer = (props) => {
    return <TermsOfUse {...props} />;
}

export default TermsOfUseRenderer;