import React, { useState } from 'react';
import { GoAChip, GoAInput } from "@abgov/react-components";

const ChipInput = ({value, onChange, name, placeholder="Start typing..."}) => {
  const [chips, setChips] = useState(!!value ? value.split(',') : []);
  const [inputValue, setInputValue] = useState('');

  const handleAddChip = () => {
    setInputValue('');
    if (inputValue.trim() !== '') {
      if (!chips.includes(inputValue.trim())) {
        const updatedChips = [...chips, inputValue.trim()];
        setChips(updatedChips);
        onChange(name, updatedChips.join(","));
      }
    }
  };

  const handleKeyPress = (_name, _value, key) => {
    if (key === 'Enter') {
      handleAddChip();
    }
  };

  const handleDeleteChip = (chip) => {
    const updatedChips = chips.filter(c => c !== chip);
    setChips(updatedChips);
    onChange(name, updatedChips.join(","))
  };

  return (
    <div>
      <div>
        {chips.map((chip) => (
          <GoAChip key={chip} deletable={true} content={chip} onClick={() => handleDeleteChip(chip)} />
        ))}
      </div>
      <GoAInput
        name={name}
        type="text"
        ariaLabelledBy={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={(_name, value) => setInputValue(value)}
        onKeyPress={handleKeyPress}
        onBlur={handleAddChip}
      />
    </div>
  );
};

export default ChipInput;