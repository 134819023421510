import { React, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GoAContainer,
  GoAButton,
  GoAPageBlock,
  GoASpacer,
  GoAGrid,
  GoAFormItem,
  GoAInput,
  GoAInputText,
  GoAInputTel,
  GoABadge,
} from "@abgov/react-components";
import DisplayContent from "../../../components/DisplayContent";
import {
  getFullName,
  getGenderFromKey,
  phoneRegExp,
  phoneExtRegExp,
  formatPhoneNumber,
} from "../../../global/Utility";
import { getDateObj } from "../../../utils";

const schema = Yup.object().shape({
  phone: Yup.string()
    .required("RESOURCES@form.phone.error")
    .matches(phoneRegExp, "RESOURCES@form.phone.invalidError")
    .min(10, "RESOURCES@form.phone.minError")
    .max(20, "RESOURCES@form.phone.maxError"),
  phoneExtension: Yup.string().matches(
    phoneExtRegExp,
    "RESOURCES@form.phoneExtension.invalidError"
  ),
  mobile: Yup.string()
    .matches(phoneRegExp, "RESOURCES@form.mobile.invalidError")
    .min(10, "RESOURCES@form.mobile.minError")
    .max(20, "RESOURCES@form.mobile.maxError"),
});

const VerifiedAccount = ({
  type,
  id,
  props,
  stepData,
  canEdit,
  defaults,
  applicationTemplate,
  applicationList,
  onNext = null,
  onPrevious = null,
  portal,
  applicationStatus,
  getResourceValue,
  user,
  isLoggedIn,
}) => {
  const {
    givenName,
    fullName,
    surName,
    middleName,
    email,
    streetAddressLine1,
    streetAddressLine2,
    locality,
    country,
    postalCode,
    province,
    gender,
    phone,
    phoneExtension,
    mobile,
    dob,
  } = user ?? {};

  const dateObj = getDateObj(dob);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "Ocrober",
    "Novermber",
    "December",
  ];

  const personalInfoForm = useFormik({
    initialValues: {
      givenName: stepData?.givenName ?? givenName ?? "",
      middleName: stepData?.middleName ?? middleName ?? "",
      surName: stepData?.surName ?? surName ?? "",
      streetAddressLine1:
        stepData?.streetAddressLine1 ?? streetAddressLine1 ?? "",
      streetAddressLine2:
        stepData?.streetAddressLine2 ?? streetAddressLine2 ?? "",
      locality: stepData?.locality ?? locality ?? "",
      province: stepData?.province ?? province ?? "",
      postalCode: stepData?.postalCode ?? postalCode ?? "",
      phone: stepData?.phone ?? phone ?? "",
      phoneExtension: stepData?.phoneExtension ?? phoneExtension ?? "",
      mobile: stepData?.mobile ?? mobile ?? "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (onNext) {
        onNext({ ...values });
      }
    },
  });

  useEffect(() => {
    if (personalInfoForm.values.phone) {
      const formattedValue = formatPhoneNumber(personalInfoForm.values.phone);

      personalInfoForm.setFieldValue("phone", formattedValue);
    }
    if (personalInfoForm.values.mobile) {
      const formattedValue = formatPhoneNumber(personalInfoForm.values.mobile);
      personalInfoForm.setFieldValue("mobile", formattedValue);
    }
  }, []);

  const handlePhoneChange = (name, value) => {
    const formattedValue = formatPhoneNumber(value);
    personalInfoForm.setFieldValue(name, formattedValue);
  };

  const handleChange = (name, value) => {
    personalInfoForm.setFieldValue(name, value);
  };

  const getAddressDisplay = () => {
    return (
      <div>
        <div>{streetAddressLine1}</div>
        {streetAddressLine2 && <div>{streetAddressLine2}</div>}
        <div>{`${locality}, ${province}${"\u00A0\u00A0"}${postalCode}${
          country ? ` ${country}` : ""
        }`}</div>
      </div>
    );
  };

  return (
    <GoAPageBlock width="704px" data-testId="personalInformation">
      <h2>
        {getResourceValue(
          "RESOURCES@personalInformation.title",
          "Confirm your information"
        )}
      </h2>
      <GoAContainer>
        <DisplayContent
          content={getResourceValue(
            "RESOURCES@personalInformation.interactiveContainer.title",
            "<h3>Personal information</h3>"
          )}
        />
        <DisplayContent
          content={getResourceValue(
            "RESOURCES@personalInformation.interactiveContainer.content",
            "<p>This information has been provided by Alberta.ca Account and cannot be edited. For questions, contact <a href='https://account.alberta.ca/' target='_blank' >Alberta.ca. Account </a><p/>"
          )}
        />
        <GoASpacer vSpacing="l" />
        <GoAGrid minChildWidth="30ch">
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@personalInformation.label.name",
                "Name"
              )}
              id="name"
            >
              {getFullName(givenName, middleName, surName)}
            </GoAFormItem>
          </div>
          <div>
            <GoAFormItem
              label={getResourceValue("RESOURCES@form.label.sex", "Sex")}
              id="sex"
            >
              {getGenderFromKey(gender)}
            </GoAFormItem>
          </div>
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@personalInformation.label.address",
                "Address"
              )}
              id="address"
            >
              {getAddressDisplay()}
            </GoAFormItem>
          </div>
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@personalInformation.label.dob",
                "Date of birth (DOB)"
              )}
              id="dob"
            >
              {!dateObj ? ' - ' : `${months[dateObj.getMonth()]} ${dateObj.getDay()}, ${dateObj.getFullYear()}`}
            </GoAFormItem>
          </div>
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@personalInformation.label.email",
                "Email address"
              )}
              id="email"
            >
              {email}
            </GoAFormItem>
          </div>
        </GoAGrid>
      </GoAContainer>

      <GoAContainer accent="thin">
        <DisplayContent
          content={getResourceValue(
            "RESOURCES@personalInformation.accentContainer.title",
            "<h3>Contact information</h3>"
          )}
        />
        <GoAGrid minChildWidth="30ch">
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.phone.label",
              "Phone number"
            )}
            id="phone"
            helpText={getResourceValue(
              "RESOURCES@form.phone.help_text",
              "Add a + to enter an international number"
            )}
            error={
              personalInfoForm.errors.phone &&
              getResourceValue(
                personalInfoForm.errors.phone,
                "Enter your phone number"
              )
            }
          >
            <GoAInput
              name="phone"
              ariaLabelledBy="phone"
              value={personalInfoForm.values.phone}
              onChange={handlePhoneChange}
            />
          </GoAFormItem>

          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@.form.phoneExtension.label",
              "Ext."
            )}
            requirement="optional"
            error={
              personalInfoForm.errors.phoneExtension &&
              getResourceValue(
                personalInfoForm.errors.phoneExtension,
                "Enter a valid phone extension."
              )
            }
            id="ext"
          >
            <GoAInputText
              name="phoneExtension"
              value={personalInfoForm.values.phoneExtension}
              onChange={handleChange}
            />
          </GoAFormItem>
        </GoAGrid>{" "}
        <GoASpacer vSpacing="l" />
        <goa-grid>
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@form.mobile.label",
              "Mobile number"
            )}
            requirement="optional"
            error={
              personalInfoForm.errors.mobile &&
              getResourceValue(
                personalInfoForm.errors.mobile,
                "Enter a valid mobile number. Mobile number must be at least 10 digits."
              )
            }
            id="mobile"
          >
            <GoAInputTel
              name="mobile"
              value={personalInfoForm.values.mobile}
              onChange={handlePhoneChange}
            />
          </GoAFormItem>
        </goa-grid>
      </GoAContainer>

      <GoAButton
        type="primary"
        onClick={personalInfoForm.handleSubmit}
        trailingIcon="arrow-forward"
        testId="next-btn"
      >
        {getResourceValue("RESOURCES@global.button.next", "Next")}
      </GoAButton>

      <GoASpacer vSpacing="l" />

      <Link to=""> Save and finish later</Link>

      <GoASpacer vSpacing="l" />
    </GoAPageBlock>
  );
};

export default VerifiedAccount;
