import axios from "axios";

const apiEndPoint = '';
const domainKey = process.env.REACT_APP_DOMAIN_KEY ?? 'evacuation';

const axiosInstance = axios.create({
  baseURL: apiEndPoint,
  headers: {
    "Content-type": "application/json",
    "domain-key": domainKey,
  },
});

export const SetupInterceptors = (navigate) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // Handle different error status codes
        const { status } = error.response;

        if (status === 404) {
          console.error('404 Error:', error.message);
          navigate('/404');
        } else if (status === 401) {
          console.error('401 Error:', error.message, error.response?.data);
          navigate('/401');
        } else {
          console.error('Error:', error.message, error.response?.data);
          // throw new Error('Unknown error occurred');
        }
      } else if (error.request) {
        // Handle network errors
        console.error('Network error:', error.request);
        // throw new Error('Network error');
      } else {
        // Something happened in setting up the request
        console.error('Error:', error.message, error.response?.data);
        // throw new Error('Unknown error occurred');
      }

      return Promise.reject(error);
    } 
  );
};


export const executePost = async (endpoint, payLoad) => {

  try {
    const result = await axiosInstance.post(`${endpoint}`, payLoad);
    
    const { success, message, errorCode, data } = result?.data ?? {};

    return { success, message, errorCode, data };
  } catch(err) {

    console.log(err);
    return { success: false };
  }
};

export const executeGet = async (endpoint, payLoad) => {
  try {
    const result = await axiosInstance.get(`${endpoint}`);

    const { data, success, message } = result;
    return { success, message, data };
  } catch {
    return { success: false };
  }
};

export const executeDelete = async (endpoint) => {
  try {
    const result = await axiosInstance.delete(endpoint);
 
    const { data, success, message } = result;
    return { success, message, data };
  } catch {
    return { success: false };
  }
};
