import React, { useState } from "react";
import {
  GoACircularProgress,
  GoAFormItem,
  GoAInputText,
  GoAButtonGroup,
  GoAButton,
  GoAContainer,
} from "@abgov/react-components";
import { getCacheKeys, getCacheValue, invalidateCache } from "../api/cache";
import { useCustomQuery } from "@common-payment-portal/common/apiUtils/useCustomQuery";

const ServerCache = () => {
  const [cacheKey, setCacheKey] = useState("");
  const [cacheState, setCacheState] = useState({
    allCacheKeys: true,
    fetchCacheValue: false,
  });

  const {
    data: cacheKeys = [],
    isLoading: isCachKeysLoading,
    refetch: refetchCacheKeys,
  } = useCustomQuery({
    queryKey: ["cacheKeys"],
    queryFn: getCacheKeys,
    enabled: true,
    staleTime: 0,
    cacheTime: 0,
  });

  const {
    data: cacheValue,
    isLoading: isValueLoading,
    refetch: refetchCacheValue,
  } = useCustomQuery({
    queryKey: ["getCacheValue", cacheKey],
    queryFn: () => getCacheValue(cacheKey),
    enabled: false,
    staleTime: 0,
    cacheTime: 0,
  });

  const { isLoading: isInvalidateCacheLoading, refetch: invalidateCacheKey } =
    useCustomQuery({
      queryKey: ["invalidateCache", cacheKey],
      queryFn: () => invalidateCache(cacheKey),
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
    });

  const handleFetchAllKeys = () => {
    refetchCacheKeys();
    setCacheKey("");
    setCacheState({
      allCacheKeys: true,
      fetchCacheValue: false,
    });
  };

  const handleFetchValue = () => {
    refetchCacheValue();
    setCacheState({
      allCacheKeys: false,
      fetchCacheValue: true,
    });
  };

  const handleDeleteCacheEntry = () => {
    invalidateCacheKey();
    setCacheKey("");
    refetchCacheKeys();
    setCacheState({
      allCacheKeys: true,
      fetchCacheValue: false,
    });
  };

  return (
    <div>
      <GoACircularProgress
        visible={
          isCachKeysLoading || isValueLoading || isInvalidateCacheLoading
        }
        variant="fullscreen"
      />

      <h2>Server Side Cache</h2>
      <div>
        <GoAFormItem
          label="Cache Key"
          helpText="Enter cache key to fetch its value or invalidate"
        >
          <GoAInputText
            name="CacheKey"
            value={cacheKey}
            onChange={(_, value) => setCacheKey(value)}
          />
        </GoAFormItem>
        <GoAButtonGroup gap="compact">
          <GoAButton
            type="primary"
            size="compact"
            leadingIcon="eye"
            onClick={handleFetchValue}
            disabled={!cacheKey || isValueLoading || isInvalidateCacheLoading}
          >
            Fetch Value
          </GoAButton>
          <GoAButton
            type="secondary"
            size="compact"
            leadingIcon="trash"
            onClick={handleDeleteCacheEntry}
            disabled={!cacheKey || isValueLoading || isInvalidateCacheLoading}
          >
            Invalidate
          </GoAButton>
        </GoAButtonGroup>
      </div>

      <div>
        <GoAButtonGroup gap="compact">
          <GoAButton
            type="primary"
            size="compact"
            leadingIcon="search"
            onClick={handleFetchAllKeys}
            disabled={isCachKeysLoading}
          >
            Show All Cache Keys
          </GoAButton>
        </GoAButtonGroup>
      </div>

      <GoAContainer accent="thick" type="non-interactive">
        {!isCachKeysLoading && cacheState.allCacheKeys && (
          <>
            <h3>Cache Keys</h3>
            <p>{(cacheKeys.data || []).join(", ")}</p>
          </>
        )}
        {!isValueLoading && cacheState.fetchCacheValue && (
          <>
            <h3>Cache Value for {cacheKey}</h3>
            <p>{JSON.stringify(cacheValue?.data ?? {}, null, 2)}</p>
          </>
        )}
      </GoAContainer>
    </div>
  );
};

export default ServerCache;
