import React from "react";
import DOMPurify from "dompurify";
import { isHTML } from "@common-payment-portal/common/ui/utils";

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute("target") &&
    (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
  ) {
    node.setAttribute("xlink:show", "new");
  }
});

const sanitizeHTML = (htmlString) => {
  return {
    __html: DOMPurify.sanitize(htmlString, { sanitize: true }),
  };
};

const DisplayContent = ({ content }) => {
  return (
    <div>
      {isHTML(content) ? (
        <span dangerouslySetInnerHTML={sanitizeHTML(content)} />
      ) : (
        <>{content}</>
      )}
    </div>
  );
};

export default DisplayContent;
