import { GoACircularProgress, GoAContainer } from "@abgov/react-components";

export const Home = () => {

  return (
    <>
      <GoAContainer variant="interactive">
        <h1>Admin HOME</h1>
      </GoAContainer>
    </>
  );
};