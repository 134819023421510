import React, { useState } from 'react';
import { GoAButtonGroup, GoAButton, GoATextArea } from "@abgov/react-components";

export default function JSONEditor({value, onChange, name, rows, error, placeholder="Paste the JSON here"}) {
  const [data, setData] = useState();
  const [formattedData, setFormattedData] = useState(value);
  const [hasStringified, setHasStringified] = useState(false);
  const [isValidJSON, setIsValidJSON] = useState(true);

  const beautifyJSON = () => {
    if (!isValidJSON) {
      alert('Invalid JSON - Please paste the valid JSON');
    }

    if (data) {
      let formatData = JSON.stringify(data, null, 2);
      setFormattedData(formatData);
      setHasStringified(false);
    }
  };

  const minifyJSON = () => {
    if (!isValidJSON) {
      alert('Invalid JSON - Please paste the valid JSON');
    }
    if (!hasStringified) {
      if (data) {
        let formatData = JSON.stringify(data);
        setFormattedData(formatData);
      } else if (formattedData) {
        let formatData = JSON.stringify(JSON.parse(formattedData));
        setFormattedData(formatData);
      }
      setHasStringified(false);
    } else {
      if (data) {
        let formatData = JSON.stringify(data);
        setFormattedData(formatData);
      } else if (formattedData) {
        let formatData = formattedData.replace(/\\/g, '');
        formatData = formatData.substring(1, formatData.length - 1);
        setFormattedData(formatData);
      }
      setHasStringified(false);
    }
  };

  const stringifyJSON = () => {
    if (!isValidJSON) {
      alert('Invalid JSON - Please paste the valid JSON');
    }
    if (!hasStringified) {
      if (data) {
        let formatData = JSON.stringify(data);
        formatData = formatData
          .replace(/\\/g, '\\\\')
          .replace(/\u0008/g, '\\b')
          .replace(/\t/g, '\\t')
          .replace(/\n/g, '\\n')
          .replace(/\f/g, '\\f')
          .replace(/\r/g, '\\r')
          .replace(/'/g, "\\'")
          .replace(/"/g, '\\"');
        setFormattedData(`"${formatData}"`);
      } else if (formattedData) {
        let formatData = JSON.stringify(JSON.parse(formattedData));
        formatData = formatData
          .replace(/\\/g, '\\\\')
          .replace(/\u0008/g, '\\b')
          .replace(/\t/g, '\\t')
          .replace(/\n/g, '\\n')
          .replace(/\f/g, '\\f')
          .replace(/\r/g, '\\r')
          .replace(/'/g, "\\'")
          .replace(/"/g, '\\"');
        setFormattedData(`"${formatData}"`);
      } 
      setHasStringified(true);
    }
  };

  const storeData = (name, value) => {
    let formatData = value;
    if (formatData?.startsWith('"')) {
      formatData = formatData.replace(/\\/g, '');
      formatData = formatData.substring(1, formatData.length - 1);
    }
    if (formatData && isJSON(formatData)) {
      setIsValidJSON(true);
      setData(JSON.parse(formatData));
      onChange(name, formatData);
    } else {
      setData();
      setFormattedData();
      setHasStringified(false);
      setIsValidJSON(false);
    }
  };

  const isJSON = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <>
      <GoAButtonGroup alignment="start">
        <GoAButton type="secondary" size="compact" onClick={stringifyJSON}>Stringify</GoAButton>
        <GoAButton type="secondary" size="compact" onClick={minifyJSON}>Minify</GoAButton>
        <GoAButton type="secondary" size="compact" onClick={beautifyJSON}>Beautify</GoAButton>
      </GoAButtonGroup>
      <GoATextArea
        name={name}
        ariaLabelledBy={name}
        placeholder={placeholder}
        width="100%"
        rows={rows}
        value={formattedData}
        onChange={storeData}
        onBlur={storeData}
        error={error || !isValidJSON}
      ></GoATextArea>
    </>
  );
}
