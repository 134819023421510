import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { SessionContext } from "../../Contexts/SessionContext";
import { saveAplication, getValidSteps, saveApplicationToLocalStorage } from "./portalUtils";
import { isEmptyObject } from "@common-payment-portal/common/utils/index";

export const usePortalContext = ({
  portal,
  template,
  getResourceValue,
  userApplication = {},
}) => {
  const { version, defaults, overview, applications } = template ?? {};

  const [userApplicationData, setUserApplicationData] = useState(
    userApplication ?? {}
  );

  const sessionContext = useContext(SessionContext);
  const { appendToTitle, isLoggedIn, user } = sessionContext ?? {};

  const { applicationType } = userApplicationData;

  const applicationList = (applications ?? []).map(({ name, displayName }) => ({
    displayName,
    name,
  }));

  const getStepData = (stepName) => {
    if (userApplicationData?.applicationData) {
      return userApplicationData.applicationData[stepName];
    }

    return {};
  };

  const applicationTemplate = applicationType
    ? (applications ?? []).find((x) => x.name === applicationType)
    : {};

  const {
    error = false,
    applicationStatus,
    canEdit = false,
    currentStep = null,
    stepData = {},
    validSteps = [],
    numberOfSteps = 0,
    isFirstStep = false,
  } = useMemo(() => {
    if (isEmptyObject(portal) || isEmptyObject(template) || !userApplicationData) {
      return { error: true };
    }

    const { lastStep } = userApplicationData;

    //if not application type exists on the userApplicationData
    if (!applicationType || !lastStep) {
      return { currentStep: overview, isFirstStep: true };
    }

    const application = (applications ?? []).find((x) => x.name === applicationType);


    const { numberOfSteps, validSteps } = getValidSteps({ application, user, template });

    //FATAL:: The selected applicationType does not exists in the template
    if (!applicationList || !application) {
      return { currentStep: null, error: true, numberOfSteps, validSteps, };
    }

    const { steps } = application;

    //FATAL: The selected application does not contain the right steps
    if (!steps || steps.length === 0) {
      return { error: true, numberOfSteps, validSteps, };
    }

    const {
      applicationStatus,
      applicationData,
      canEdit = true,
    } = userApplicationData;

    const nextStep = validSteps[lastStep];

    //FATAL:: probably reached the last step but the application status should have been submitted
    if (!nextStep && applicationStatus !== "SUBMITTED") {
      return { error: true, numberOfSteps, validSteps, };
    }

    //FATAL: no next step and the application is not marked as submittedapplication seem to be finished now
    if (!nextStep) {
      return { applicationStatus, canEdit, error: true, stepData, numberOfSteps, validSteps, };
    }

    //get step data for the particular step
    const stepData = (applicationData ?? {})[`${nextStep.name}`] ?? {};

    return {
      applicationStatus,
      canEdit,
      error: false,
      stepData,
      currentStep: nextStep,
      numberOfSteps, 
      validSteps,
    };
  }, [portal, template, userApplicationData, applications, applicationType]);

  useEffect(() => {
    if (currentStep?.name === "Overview" && appendToTitle) {
      appendToTitle(null);
    } else if (applicationType && appendToTitle) {
      appendToTitle(getResourceValue(applicationTemplate?.displayName) ?? "");
    }
  }, [applicationType, currentStep]);

  const getStepByOrder = useCallback(
    (increment) => {
      var order = 0;

      if (currentStep?.name !== "Overview") {
        order = validSteps[currentStep?.name]?.order + increment;
      } else {
        order = 1;
      }
      
      for(var id in validSteps) {
        if (validSteps[id].order === order) {
          return validSteps[id];
        }
      }
    },
    [currentStep, applicationTemplate?.steps, validSteps]
  );

  const onNext = useCallback(
    async (data) => {
      if (currentStep.name === "Overview") {
        setUserApplicationData((s) => ({
          ...s,
          applicationType: data?.applicationType,
          lastStep: applications
            .find((x) => x.name === data?.applicationType)
            .steps.find((x) => x.order === 1)?.name,
          applicationData:
            applicationType === data?.applicationType
              ? { ...(s?.applicationData ?? {}) }
              : {},
        }));
      } else {
        const nextStep = getStepByOrder(1)?.name;

        saveApplicationToLocalStorage({
          isLoggedIn,
          application: userApplicationData,
          lastStep: nextStep,
          step: currentStep,
          stepData: data,
        });

        setUserApplicationData((s) => ({
          ...s,
          lastStep: nextStep,
          applicationData: {
            ...(s?.applicationData ?? {}),
            [`${currentStep.name}`]: {
              ...data,
            },
          },
        }));
      }
    },
    [isLoggedIn, userApplicationData, currentStep, applicationType]
  );

  const onPrevious = useCallback(async () => {
    let lastStep = null;
    if (currentStep || currentStep?.name !== "Overview") {
      lastStep = getStepByOrder(-1)?.name;
    }
    setUserApplicationData((s) => ({
      ...s,
      lastStep,
    }));
  }, [userApplicationData, currentStep]);

  //TODO saveStepData to the backend and update the application status
  const saveStepData = useCallback(
    async (data) => {
      // if (currentStep.name === "Overview") {
      //   setUserApplicationData((s) => ({
      //     ...s,
      //     applicationType: data?.applicationType,
      //   }));
      // } else {
      //   setUserApplicationData((s) => ({
      //     ...s,
      //     applicationData: {
      //       ...(s?.applicationData ?? {}),
      //       [`${currentStep.name}`]: {
      //         ...data,
      //       },
      //     },
      //   }));
      // }
    },
    [userApplicationData]
  );

  return {
    error,
    version,
    defaults,
    overview,
    applicationType,
    applicationList,
    applicationTemplate,
    portal,
    currentStep,
    isFirstStep,
    applicationStatus,
    stepData,
    canEdit,
    onNext,
    onPrevious,
    saveStepData,
    isLoggedIn,
    user,
    getStepData,
    numberOfSteps,
    validSteps,
  };
};
