"use strict";
/*
  Formats the the output from the popup so that the quill editor can properly display it
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.Compose = exports.FixTagSpaceCloseTag = exports.FixTagSpaceOpenTag = exports.PreserveNewlinesPTags = exports.PreserveNewlinesBr = exports.ConvertMultipleSpacesToSingle = exports.OutputHTMLParser = void 0;
function OutputHTMLParser(inputHtmlFromQuillPopup) {
    return Compose([
        ConvertMultipleSpacesToSingle,
        FixTagSpaceOpenTag,
        FixTagSpaceCloseTag,
        PreserveNewlinesBr,
        PreserveNewlinesPTags,
    ], inputHtmlFromQuillPopup);
}
exports.OutputHTMLParser = OutputHTMLParser;
function ConvertMultipleSpacesToSingle(input) {
    return input.replace(/\s+/g, " ").trim();
}
exports.ConvertMultipleSpacesToSingle = ConvertMultipleSpacesToSingle;
function PreserveNewlinesBr(input) {
    return input.replace(/<br([\s]*[\/]?>)/g, "<p> </p>");
}
exports.PreserveNewlinesBr = PreserveNewlinesBr;
function PreserveNewlinesPTags(input) {
    return input.replace(/<p><\/p>/g, "<p> </p>");
}
exports.PreserveNewlinesPTags = PreserveNewlinesPTags;
function FixTagSpaceOpenTag(input) {
    // Open tag remove space on inside
    return input.replace(/(<(?!\/)[\w=\."'\s]*>) /g, "$1");
}
exports.FixTagSpaceOpenTag = FixTagSpaceOpenTag;
function FixTagSpaceCloseTag(input) {
    // Close tag remove space on inside
    return input.replace(/ (<\/[\w]+>)/g, "$1");
}
exports.FixTagSpaceCloseTag = FixTagSpaceCloseTag;
function Compose(functions, input) {
    return functions.reduce(function (acc, cur) { return cur(acc); }, input);
}
exports.Compose = Compose;
