
const isEmptyObject = (obj) => {
    if (!obj || obj == null || obj === undefined) {
        return true
    }
    
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

module.exports = {
    isEmptyObject
}