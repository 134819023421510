import { useQuery } from "@tanstack/react-query";

const staleTime = process.env.REACT_APP_STALE_TIME ?? 300000;
const cacheTime = process.env.REACT_APP_CACHE_TIME ?? 3600000;

export const useCustomQuery = (key, fetchData, options = {}) => {
  return useQuery(key, fetchData, {
    staleTime: staleTime, // Default stale time
    cacheTime: cacheTime, // Default cache time
    refetchOnWindowFocus: false, // Disable automatic refetch on window focus
    refetchInterval: false, // Disable automatic interval refetching
    onError: (error) => {
      // Custom error handling for the query
      console.error('Error fetching data:', error);
    },
    ...options, // Allow overriding default options
  });
};