import React from 'react'
import { Link } from 'react-router-dom';

const NotAuthorized = () => {
  return (
    <div>
        <h2>401</h2>
        <p>Not authorized</p>
        <Link to="/">Go back to Home</Link>
    </div>
  )
}

export default NotAuthorized