const validationConstants = {
  invalidType: 'INVALID_TYPE',
  required: 'REQUIRED',
  maxLength: 'MAX_LENGTH',
  minLength: 'MIN_LENGTH',
  invalid: 'INVALID',
  passwordWeak: 'PASSWORD_WEAK',
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


module.exports = {
  validationConstants,
  phoneRegExp,
};
