import { isEditableField, getDefaultValue, } from "@common-payment-portal/common/form/fieldTypes";
import { useMemo } from "react";

export const useDynamicForm = ({ form, data = {}, substitutions }) => {
  const {
    defaultValues,
    title,
    error,
    isLoading,
    fields,
    description,
    templateFields,
    formSettings,
  } = useMemo(() => {
    if (!form) {
      return { error: true, isLoading: false };
    }

    let error = false;
    let isLoading = false;
    const { title, description, templateFields, formSettings, fields = [] } = form ?? {};

    if (fields?.length === 0) {
      error = true;
    }

    const newFields = [ ...fields ];
    const defaultValues = {};

    newFields.forEach((field) => {
      const { type, subType } = field;
      
      if (isEditableField(field.type)) {
        defaultValues[field.name] = data[field.name] ?? field.defaultValue ?? getDefaultValue({ type, subType});
      }

      (templateFields ?? []).forEach((x) => {
        field.label = field.label.replace(`##${x}##`, (substitutions?.[x] ?? `##${x}##`));
      });
    });

    return {
      error,
      isLoading,
      title,
      description,
      templateFields,
      formSettings,
      fields: newFields,
      defaultValues,
    };
  }, [form, data, substitutions]);

  return {
    defaultValues,
    title,
    description,
    templateFields,
    formSettings,
    error,
    isLoading,
    fields,
  };
};
