import { executeGet } from "@common-payment-portal/common/apiUtils";

export const getCacheKeys = async () => {
  return await executeGet("/api/cache/allKeys");
};

export const getCacheValue = async (key) => {
  return await executeGet("/api/cache/value/" + key);
};

export const invalidateCache = async (key) => {
  return await executeGet("/api/cache/invalidate/" + key);
};
