import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
        <h2>404</h2>
        <p><strong>Page not found:</strong></p>
        <p>The requested page could not be found.</p>
        <Link to="/">Go back to Home</Link>
    </div>
  )
}

export default NotFound