import React from "react";
import { fieldTypes } from "@common-payment-portal/common/form/fieldTypes";
import { DynamicFormInput } from "./components/DynamicFormInput";
import { DynamicFormCheck } from "./components/DynamicFormCheck";
import { DynamicFormLabel } from "./components/DynamicFormLabel";
import { DynamicFormInputSelect } from "./components/DynamicFormInputSelect";
import { DynamicFormFile } from "./components/DynamicFormFile";

export const getFields = ({ field, form }) => {
  switch (field.type) {
    case fieldTypes.header:
    case fieldTypes.paragraph:
      return (
        <DynamicFormLabel field={field} form={form} />
      );
    case fieldTypes.select:
      return (
        <DynamicFormInputSelect field={field} form={form} />
      );
    case fieldTypes.file:
      return (
        <DynamicFormFile field={field} form={form} />
      );
    case fieldTypes.check:
      return (
        <DynamicFormCheck field={field} form={form} />
      );
    case fieldTypes.input:
    default:
      return (
        <DynamicFormInput field={field} form={form} />
      );
  }
};
