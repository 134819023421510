/* eslint-disable */

import React, { useState } from "react";
import { useFormik } from "formik";
import { GoAButton, GoAContainer } from "@abgov/react-components";
import { getFields } from "./utils";
import { validateInput } from "@common-payment-portal/common/form/formValidation";
import parse from "html-react-parser";

export const DynamicFormLayout = ({
  title,
  description,
  formSettings,
  data,
  fields,
  form,
  onSubmit,
}) => {
  const [isBusy, setIsBusy] = useState(false);

  const { submitEnabled, submitButtonLabel = "Submit" } = formSettings ?? {};

  const templatForm = useFormik({
    initialValues: {
      ...data,
      isBusy: false,
    },
    enableReinitialize: true,
    validate: (values) => validateInput({ form, data: values }),
    onSubmit: async (values) => {
      const { success, message } = await onSubmit(values);
      if (success) {
        console.success(message ?? `${title} form saved successfully`);
      } else {
        console.error("Something went wrong");
      }
      setIsBusy(false);
    },
  });

  return (
    <div>
      <h1>{title}</h1>
      <div>
        <GoAContainer type="interactive">
          {description && <div>{parse(description)}</div>}
          {(fields ?? []).map((field) =>
            getFields({ field, form: templatForm })
          )}
          {submitEnabled && 
          <GoAButton type="submit" onClick={templatForm.handleSubmit} primary>
            {submitButtonLabel}
          </GoAButton>}
        </GoAContainer>
      </div>
    </div>
  );
};
