import React from "react";
import { fieldSubTypes } from "@common-payment-portal/common/form/fieldTypes";
import { GoADatePicker, GoAFormItem, GoAInput } from "@abgov/react-components";

const getAdditionalAttributes = (field) => {
  const result = {};
  if (field.subType === fieldSubTypes.textarea) {
    result["minRows"] = 2;
    result["multiline"] = true;
  }

  return result;
};

export const DynamicFormInput = ({ field, form }) => {
  const { name, label, subType } = field;

  if (subType === fieldSubTypes.date) {
    return (
      <GoAFormItem label={label} error={form?.errors?.[name]} name={name}>
        <GoADatePicker
          onChange={(name, value) => form.setFieldValue(name, value)}
          value={form.values?.[`${name}`]}
        />
      </GoAFormItem>
    );
  } else {
    return (
      <GoAFormItem
        label={label}
        requirement="required"
        error={form.errors?.[name]}
        name={name}
      >
        <GoAInput
          width="100%"
          value={form.values?.[name]}
          onChange={(v, t) => form.setFieldValue(v, t)}
          error={!!form.errors?.[name]}
          {...getAdditionalAttributes(field)}
        />
      </GoAFormItem>
    );
  }
};
