import "./IndianRegistrationNumber.css";
import * as Yup from "yup";
import { GoAFormItem, GoABlock, GoAInput } from "@abgov/react-components";
import { onlyNumberKey } from "../global/Utility";

export const indianRegistrationNumberSchema = Yup.object().shape({
  band: Yup.string().matches(/^\d{3}$/, "RESOURCES@global.error.invalidLength"),
  family: Yup.string().matches(
    /^\d{5}$/,
    "RESOURCES@global.error.invalidLength"
  ),
  position: Yup.string().matches(
    /^\d{2}$/,
    "RESOURCES@global.error.invalidLength"
  ),
});

const IndianRegistrationNumber = ({ form, getResourceValue }) => {
  const handleChange = (name, value) => {
    form.setFieldValue(name, value);
  };
  onlyNumberKey("band");
  onlyNumberKey("position");
  onlyNumberKey("family");
  return (
    <GoABlock>
      <div>
        <div className="label regular">
          {getResourceValue(
            "RESOURCES@form.indianRegistrationNumber.label",
            "INAC, DIAND, Treaty or Status Number"
          )}
          <em> (all fields are optional)</em>
        </div>
        <GoABlock gap="xs">
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@form.indianRegistrationNumber.band.label",
                "Band"
              )}
              helpText={getResourceValue(
                "RESOURCES@form.indianRegistrationNumber.band.help_text",
                "3 digits"
              )}
              error={
                form.errors.band &&
                getResourceValue(form.errors.band, "Invalid length")
              }
            >
              <GoAInput
                name="band"
                id="band"
                onChange={handleChange}
                maxLength={3}
                width="10ch"
                value={form.values.band}
              ></GoAInput>
            </GoAFormItem>
          </div>
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@form.indianRegistrationNumber.family.label",
                "Family"
              )}
              helpText={getResourceValue(
                "RESOURCES@form.indianRegistrationNumber.family.help_text",
                "5 digits"
              )}
              error={
                form.errors.family &&
                getResourceValue(form.errors.family, "Invalid length")
              }
            >
              <GoAInput
                name="family"
                id="family"
                onChange={handleChange}
                maxLength={5}
                width="10ch"
                value={form.values.family}
              ></GoAInput>
            </GoAFormItem>
          </div>
          <div>
            <GoAFormItem
              label={getResourceValue(
                "RESOURCES@form.indianRegistrationNumber.position.label",
                "Position"
              )}
              helpText={getResourceValue(
                "RESOURCES@form.indianRegistrationNumber.position.help_text",
                "2 digits"
              )}
              error={
                form.errors.position &&
                getResourceValue(form.errors.position, "Invalid length")
              }
            >
              <GoAInput
                name="position"
                id="position"
                onChange={handleChange}
                maxLength={2}
                width="5ch"
                value={form.values.position}
              ></GoAInput>
            </GoAFormItem>
          </div>
        </GoABlock>
      </div>
    </GoABlock>
  );
};

export default IndianRegistrationNumber;
