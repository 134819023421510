import React from "react";
import { GoACheckbox, GoAFormItem } from "@abgov/react-components";

export const DynamicFormCheck = ({ field, form }) => {
  const { label, name } = field;

  return (
    <GoAFormItem label={label} error={form?.errors?.[name]} name={name}>
      <GoACheckbox
        checked={form?.values?.[`${name}`]}
        onChange={(name, checked, value) => {
          form.setFieldValue(name, checked);
        }}
      />
    </GoAFormItem>
  );
};
