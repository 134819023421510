"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuillHtmlLogger = void 0;
var QuillHtmlLogger = /** @class */ (function () {
    function QuillHtmlLogger() {
        this.debug = false;
    }
    QuillHtmlLogger.prototype.setDebug = function (debug) {
        this.debug = debug;
    };
    QuillHtmlLogger.prototype.prefixString = function () {
        return "</> quill-html-edit-button: ";
    };
    Object.defineProperty(QuillHtmlLogger.prototype, "log", {
        get: function () {
            if (!this.debug) {
                return function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                };
            }
            var boundLogFn = console.log.bind(console, this.prefixString());
            return boundLogFn;
        },
        enumerable: false,
        configurable: true
    });
    return QuillHtmlLogger;
}());
exports.QuillHtmlLogger = QuillHtmlLogger;
