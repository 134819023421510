import { useCallback, useMemo } from 'react';

export const useSearchData = ({ data, searchCriteria, sortKey='name', filterKey='name' }) => {
  
    const sortFn = useCallback((a, b) => {
        const aName = a[sortKey] ?? '';
        const bName = b[sortKey] ?? '';
        return aName.localeCompare(bName);
    }, [sortKey]);


    const sortedData = useMemo(() => {
        if (!searchCriteria || searchCriteria.length <= 2 || !data || data.length === 0) {
            return (data ?? []).sort(sortFn);
        }

        const lowerSearch = searchCriteria.toLowerCase();
        const words = (lowerSearch.split(' ') ?? []);

        const filteredData = [];
        data.forEach((x) => {
            const key = x[filterKey] ?? '';
            const lowerKey = key.toLowerCase();
            //if any word is found in the key, then we will return the data
            const found = words.some((word) => lowerKey.includes(word));
            if (found) {
                filteredData.push(x)
            }
        });

        filteredData.sort(sortFn);

        return filteredData;
  }, [data, searchCriteria]);

  return { sortedData, count: data?.length ?? 0, currentCount: sortedData?.length ?? 0 };
};
