import React from "react";
import { fieldTypes } from "@common-payment-portal/common/form/fieldTypes";
import parse from "html-react-parser";

export const DynamicFormLabel = ({ field }) => {
  return (
    <div>
      {(field.type === fieldTypes.paragraph ||
        field.type === fieldTypes.header) &&
        parse(field.label)}
    </div>
  );
};
