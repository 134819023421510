export const phoneRegExp =
  /^\+\d{1,20}$|^\(\d{3}\)\s?\d{3}-?\d{4}$|^\d{3}\s?\d{3}-?\d{4}$/;
export const phoneExtRegExp = /^\d+$/;
export const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export const formatPhoneNumber = (phoneNumber) => {
  // Remove non-digit characters
  const digitsOnly = phoneNumber.replace(/[^\d+]/g, "");

  // Check if the phone number starts with '+'
  if (digitsOnly.startsWith("+")) {
    return digitsOnly;
  }

  // Add parentheses and hyphen for phone numbers without '+'
  if (digitsOnly.length >= 10) {
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
      3,
      6
    )}-${digitsOnly.slice(6, 10)}`;
  }

  return digitsOnly;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatPostalCode = (postalCode) => {
  return (postalCode ?? "")
    .replace(/\s/g, "") // Remove whitespace
    .slice(0, 6) // Limit input to 6 characters (e.g., A1A1A1)
    .replace(/([A-Za-z]\d[A-Za-z])(\d[A-Za-z]\d)/, "$1 $2")
    .toUpperCase(); // Format as A1A 1A1
};

export const getGenderFromKey = (key) => {
  const gender = genderList.find((x) => x.key == key);

  return gender?.label || "";
};

export const genderList = [
  { key: 0, label: "Other/Not prefer to say" },
  { key: 1, label: "Male" },
  { key: 2, label: "Female" },
];

export function onlyNumberKey(id) {
  document.getElementById(id)?.addEventListener("keypress", (event) => {
    if (isNaN(event.key)) {
      event.preventDefault();
    }
  });
}

export function getFullName(firstName, middleName, lastName) {
  return firstName + " " + middleName ? middleName + " " : "" + lastName;
}
