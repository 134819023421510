import React from "react";
import ReactQuill from "react-quill";
import RichTextEditorToolbar, {
  modules,
  formats,
} from "./RichTextEditorToolbar";
import "react-quill/dist/quill.snow.css"; // Import styles
import "./RichTextEditor.css";

function RichTextEditor({
  value,
  onChange,
  name,
  placeholder = "Start typing...",
}) {
  // Quill.register('modules/htmlEditButton', htmlEditButton);

  // const modules = {
  //     toolbar: [
  //         [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
  //         ['bold', 'italic', 'underline', 'strike'],
  //         [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  //         ['link'],
  //     ],
  //     htmlEditButton: {}
  //   };

  // const formats = [
  //     'header', 'font', 'bold', 'italic', 'underline', 'strike', 'blockquote',
  //     'list', 'bullet', 'link',
  // ];

  return (
    <div className="text-editor">
      <RichTextEditorToolbar />
      <ReactQuill
        value={value}
        name={name}
        style={{ height: "60ch", width: "100%" }}
        onChange={(v) => onChange(name, v)}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
      />
    </div>
  );
}

export default RichTextEditor;
