import React from "react";
import {
  GoADropdown,
  GoADropdownItem,
  GoAFormItem,
  GoARadioGroup,
  GoARadioItem,
} from "@abgov/react-components";
import { fieldSubTypes } from "@common-payment-portal/common/form/fieldTypes";

export const DynamicFormInputSelect = ({ field, form }) => {
  const { label, name, subType } = field;
  
  if ( subType === fieldSubTypes.radio) {
    return (<GoAFormItem label={label} error={form?.errors?.[name]} name={name}>
      <GoARadioGroup name={name} value={form?.values[name]} onChange={(v,t) => form.setFieldValue(v, t)} orientation="horizontal">
        {(field?.options ?? []).map(({ label, value }) => (
            <GoARadioItem value={value} label={label} />
          ))}
      </GoARadioGroup>
    </GoAFormItem>)
  }

  return (
    <GoAFormItem label={label} error={form?.errors?.[name]} name={name}>
      <GoADropdown
        name={name}
        value={form?.values[name]}
        filterable
        error={!!form?.errors?.[name]}
        onChange={(v, t) => {
          form.setFieldValue(v, t);
        }}
      >
        {(field?.options ?? []).map(({ label, value }) => (
          <GoADropdownItem value={value} label={label} />
        ))}
      </GoADropdown>
    </GoAFormItem>
  );
};
