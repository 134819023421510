import React, { useEffect, useState } from "react";
import {
  GoAButton,
  GoATable,
  GoATableSortHeader,
  GoAInput,
  GoATextArea,
  GoACircularProgress,
  GoAPageBlock,
  GoAPagination,
  GoADivider,
  GoAGrid,
  GoAFormItem,
} from "@abgov/react-components";
import { useParams } from "react-router-dom";
import { getEmails, updateEmails } from "../api/admin";
import { useCustomQuery } from "@common-payment-portal/common/apiUtils/useCustomQuery";
import { reactPlugin } from "@common-payment-portal/common/logging";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useSearchData } from "./hooks/useSearchData";
import { RichTextEditor } from "@common-payment-portal/lib";

const EmailContainer = () => {
  const { id } = useParams();

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: ["getEmails", "id"],
    queryFn: () => getEmails({ id }),
    refetchInterval: 0,
  });

  const { data: emails = [] } = data ?? {};

  return (
    <GoAPageBlock width="704px">
      <h1>Email Templates</h1>
      <GoACircularProgress
        variant="fullscreen"
        visible={isLoading}
        message="Loading..."
      />
      {!isLoading && <EmailsForm id={id} data={emails} />}
    </GoAPageBlock>
  );
};

export const EmailsForm = ({ id, data }) => {
  const [emails, setEmails] = useState(data ?? []);
  const [searchCriteria, setSearchCriteria] = useState("");

  const { sortedData, count } = useSearchData({
    data: emails,
    searchCriteria,
    sortKey: "key",
    filterKey: "key",
  });

  const update = async () => {
    if (!emails) {
      return;
    }

    await updateEmails({ emails: [...emails] });
  };

  const handleChange = (key, event, value) => {
    setEmails((prev) => [
      ...(prev ?? []).filter((x) => x["id"] !== key),
      ...[{ id, ...value }],
    ]);
  };

  const handleSearch = (value) => {
    setSearchCriteria(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setSearchCriteria("");
    }
  };

  return (
    <>
      <GoAGrid minChildWidth="30ch">
        <div gap="l">
          <GoAButton style={{ pb: "10px" }} onClick={update}>
            Update
          </GoAButton>
        </div>
        <div>
          <GoAInput
            name="searchCriteria"
            placeholder="Enter the email name and hit enter to trigger search"
            testId="search-criteria"
            value={searchCriteria}
            onKeyDown={handleKeyDown}
            width="100%"
            onChange={(name, value) => handleSearch(value)}
          />
        </div>
      </GoAGrid>
      <div></div>
      <GoATable width="100%">
        <tbody>
          {(sortedData ?? []).length === 0 && (
            <tr>
              <td>No rows found</td>
            </tr>
          )}
          {(sortedData ?? []).map(
            ({
              name,
              id,
              paymentPortalId,
              codeName,
              subject,
              content,
              allowedScopesJson,
            }) => (
              <tr key={id}>
                <td>
                  <h4>Name: {name}</h4>
                  <GoAFormItem
                    label="Subject"
                    requirement="required"
                    id="subject"
                  >
                    <GoATextArea
                      name="value-text"
                      value={subject}
                      width="100%"
                      style={{ width: "400px" }}
                      onChange={(e, v) =>
                        handleChange(id, e, {
                          name,
                          id,
                          paymentPortalId,
                          codeName,
                          content,
                          allowedScopesJson,
                          subject: v,
                        })
                      }
                    ></GoATextArea>
                  </GoAFormItem>

                  <GoAFormItem
                    label="Content"
                    requirement="required"
                    id="content"
                  >
                    <RichTextEditor
                      value={content}
                      onChange={(e, v) =>
                        handleChange(id, e, {
                          name,
                          id,
                          paymentPortalId,
                          codeName,
                          subject,
                          allowedScopesJson,
                          content: v,
                        })
                      }
                    />
                  </GoAFormItem>
                </td>
              </tr>
            )
          )}
        </tbody>
      </GoATable>
    </>
  );
};

export const Emails = withAITracking(reactPlugin, EmailContainer);
