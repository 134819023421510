import React, { useEffect, useState } from "react";
import {
  GoAButton,
  GoATable,
  GoATableSortHeader,
  GoAInput,
  GoATextArea,
  GoACircularProgress,
  GoAPageBlock,
  GoAPagination,
  GoADivider,
  GoAGrid,
} from "@abgov/react-components";
import { useParams } from "react-router-dom";
import { getResourceString, updateResourceString } from "../api/admin";
import { useCustomQuery } from "@common-payment-portal/common/apiUtils/useCustomQuery";
import { reactPlugin } from "@common-payment-portal/common/logging";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useSearchData } from "./hooks/useSearchData";
import { RichTextEditor } from "@common-payment-portal/lib";
import { isHTML } from "@common-payment-portal/common/ui/utils";
import parse from 'html-react-parser';



const ResourceStringContainer = () => {
  const { id } = useParams();

  const { data, isLoading, refetch } = useCustomQuery({
    queryKey: ["getResourceString", "id"],
    queryFn: () => getResourceString({id}),
    refetchInterval: 0,
  });

  const { data: resourceStringData = [] } = data ?? {}

  return (
    <GoAPageBlock width="704px">
      <h1>Resource Strings</h1>
      <GoACircularProgress
        variant="fullscreen"
        visible={isLoading}
        message="Loading..."
      />
      {!isLoading && <ResourceStringForm id={id} data={resourceStringData} />}
    </GoAPageBlock>
  );
};

export const ResourceStringForm = ({ id, data }) => {
  const [resourceStrings, setResourceStrings] = useState(data ?? []);
  const [searchCriteria, setSearchCriteria] = useState("");

  const { sortedData, count } = useSearchData({
    data: resourceStrings,
    searchCriteria,
    sortKey: "key",
    filterKey: "key",
  });

  const updateResource = async () => {
    if (!resourceStrings) {
      return;
    }

    await updateResourceString({ id, resourceStrings: [...resourceStrings] });
  };

  const handleChange = (key, event, value) => {
    setResourceStrings((prev) => [
      ...(prev ?? []).filter((x) => x["key"] !== key),
      ...[{ key, value }],
    ]);
  };

  const handleSearch = (value) => {
    setSearchCriteria(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setSearchCriteria("");
    }
  };

  return (
    <>
      <GoAGrid minChildWidth="30ch">
        <div gap="l">
          <GoAButton style={{pb: '10px'}} onClick={updateResource}>Update</GoAButton>
        </div>
        <div>
          <GoAInput
            name="searchCriteria"
            placeholder="Enter the resource key and hit enter to trigger search"
            testId="search-criteria"
            value={searchCriteria}
            onKeyDown={handleKeyDown}
            width="100%"
            onChange={(name, value) => handleSearch(value)}
          />
        </div>
      </GoAGrid>
        <div>
        </div>
        <GoATable  width="100%">
          <thead>
            <tr>
              <th width="100px">
                Key
              </th>
            </tr>
          </thead>
          <tbody>
            {(sortedData ?? []).length === 0 && (
              <tr>
                <td>No rows found</td>
              </tr>
            )}
            {(sortedData ?? []).map(({ key, value }) => (
              <tr key={key}>
                <td>
                  <h4>{key}</h4>
                  <GoATextArea
                      name="value-text"
                      value={value}
                      width='100%'
                      style={{width: '400px'}}
                      onChange={(e, v) => handleChange(key, e, v)}
                    ></GoATextArea></td>
              </tr>
            ))}
          </tbody>
        </GoATable>
    </>
  );
};

export const ResourceString = withAITracking(reactPlugin, ResourceStringContainer);