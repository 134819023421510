import { useEffect, useState, useCallback } from 'react';
import { useCustomQuery } from "./useCustomQuery";
import { checkSession } from "./auth";

export const useSessionManagement = () => {
    const [session, setSession] = useState({
      isLoggedIn: false,
      user: {},
      portal: {},
    });
  
    const { data: checkSessionData, isLoading } = useCustomQuery({
      queryKey: ['checkSession'],
      queryFn: checkSession,
      staleTime: Infinity,
    });
  
    const setUserContext = useCallback(({ isLoggedIn = false, user = {}, portal = {} }) => {
      setSession((prev) => ({ ...prev, isLoggedIn, user, portal }));
    }, []);
  
    const clearSession = useCallback(() => {
      setSession((prev) => ({ ...prev, isLoggedIn: false, user: {} }));
    }, []);
  
    useEffect(() => {
      if (checkSessionData) {
        const { success = false, data } = checkSessionData ?? {};
        const { isLoggedIn = false, user, portal } = data ?? {};
  
        if (success) {
          setUserContext({ isLoggedIn, user, portal });
        }
      }
    }, [checkSessionData, setUserContext]);
  
    return {
      session,
      isLoading,
      clearSession,
    };
  }