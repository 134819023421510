import {
  executePost,
  executeDelete,
} from "@common-payment-portal/common/apiUtils";

export const createNewPortal = async ({ paymentPortal }) => {
  return await executePost("/api/portalAdmin/create", paymentPortal);
};

export const getPortals = async () => {
  return await executePost("/api/portalAdmin/list");
};

export const deletePortalById = async ({id}) => {
  return await executeDelete(`/api/portalAdmin/deletePortal?id=${id}`);
};

export const getResourceString = async ({id}) => {
  return await executePost(`/api/portalAdmin/getResourceStrings`, id);
};

export const updateResourceString = async ({id, resourceStrings}) => {
  return await executePost(
    `/api/portalAdmin/updateResourceStrings`,
    { id, resourceStrings }
  );
};

export const getEmails = async ({id}) => {
  return await executePost(`/api/portalAdmin/getEmails`, id);
};

export const updateEmails = async ({emails}) => {
  return await executePost(
    `/api/portalAdmin/updateEmails`,
    emails
  );
};
