const { ApplicationInsights } = require('@microsoft/applicationinsights-web');
const { ReactPlugin } = require('@microsoft/applicationinsights-react-js');

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        connectionString: `InstrumentationKey=${process.env.REACT_APP_ApplicationInsights ?? "00000000-0000-0000-0000-000000000000"}`,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});

appInsights.loadAppInsights();

module.exports = {
    reactPlugin,
    appInsights,
}