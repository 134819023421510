import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GoAPageBlock,
  GoAContainer,
  GoAFormItem,
  GoARadioGroup,
  GoARadioItem,
  GoASpacer,
  GoAButtonGroup,
  GoAButton
} from "@abgov/react-components";
import { Link } from "react-router-dom";
import FirstNationStatusDetail from "../../components/FirstNationStatusDetail";

import { useCallback } from "react";
const schema = Yup.object().shape({
  isIndigenous: Yup.string().required(
    "RESOURCES@global.error.selectAnswer"
  ),
  band: Yup.string()
      .when('isIndigenous', {
          is: (isIndigenous) => isIndigenous === 'Y',
          then: () => Yup.string()
              .matches(/^\d{3}$/, 'RESOURCES@global.error.invalidLength')
      }),
  family: Yup.string()
      .when('isIndigenous', {
          is: (isIndigenous) => isIndigenous === 'Y',
          then: () => Yup.string()
              .matches(/^\d{5}$/, 'RESOURCES@global.error.invalidLength')
      }),
  position: Yup.string()
      .when('isIndigenous', {
          is: (isIndigenous) => isIndigenous === 'Y',
          then: () => Yup.string()
              .matches(/^\d{2}$/, 'RESOURCES@global.error.invalidLength')
      }),
  FNIHB: Yup.string()
      .when('isIndigenous', {
          is: (isIndigenous) => isIndigenous === 'Y',
          then: () => Yup.string()
              .matches(/^\d{8}$/, 'RESOURCES@familyMemberForm.FNIHB.invalidError')
      }),
  livesOnReserve: Yup.string()
      .when('isIndigenous', {
          is: (isIndigenous) => isIndigenous === 'Y',
          then: () => Yup.string()
              .required('RESOURCES@global.error.selectAnswer')
      }) 
});

const FirstNationStatus = ({
  id,
  props,
  stepData,
  canEdit,
  defaults,
  applicationTemplate,
  applicationList,
  getResourceValue,
  portal,
  onNext = null,
  onPrevious = null,
  saveStepData = null,
  applicationStatus,
}) => {
  const firstNationStatusForm = useFormik({
    initialValues: {
      isIndigenous: stepData?.isIndigenous ?? "",
      FNIHB: stepData?.FNIHB ?? "",
      livesOnReserve: stepData?.livesOnReserve ?? "",
      band: stepData?.band ?? "",
      family: stepData?.family ?? "",
      position: stepData?.position ?? "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (onNext) {
        onNext(values);
      }
    },
  });

  const handleChange = (v, t) => {
    firstNationStatusForm.setFieldValue(v, t);
  };

  const handlePrevious = useCallback(async () => {
    if (saveStepData) {
      await saveStepData(firstNationStatusForm.values);
    }

    if (onPrevious) {
      onPrevious();
    }
  }, [firstNationStatusForm]);
  
  return (
    <GoAPageBlock width="704px">
      <GoASpacer vSpacing="l" />

      <GoAFormItem
        label={getResourceValue(
          "RESOURCES@firstNationStatusForm.isIndigenous.question",
          "Do you identify as First Nations, Inuit or Metis?"
        )}
        labelSize="large"
        error={
          firstNationStatusForm.errors.isIndigenous &&
          getResourceValue(
            firstNationStatusForm.errors.isIndigenous,
            "Select an answer"
          )
        }
        id="isIndigenous"
      >
        <GoARadioGroup
          name="isIndigenous"
          value={firstNationStatusForm.values.isIndigenous}
          onChange={handleChange}
        >
          <GoARadioItem label="Yes" value="Y" />
          <GoARadioItem label="No" value="N" />
        </GoARadioGroup>
      </GoAFormItem>

      <GoASpacer vSpacing="l" />

      {firstNationStatusForm.values.isIndigenous === "Y" && (
        <div>
          <GoAContainer>
            <div>
              <FirstNationStatusDetail
                form={firstNationStatusForm}
                getResourceValue={getResourceValue}
              />
            </div>
          </GoAContainer>
          <GoASpacer vSpacing="l" />
          <GoAFormItem
            label={getResourceValue(
              "RESOURCES@firstNationStatusForm.livesOnReserve.question",
              "Do you live on reserve?"
            )}
            error={
              firstNationStatusForm.errors.livesOnReserve &&
              getResourceValue(
                firstNationStatusForm.errors.livesOnReserve,
                "Select an answer"
              )
            }
            id="livesOnReserve"
          >
            <GoARadioGroup
              name="livesOnReserve"
              value={firstNationStatusForm.values.livesOnReserve}
              onChange={handleChange}
              orientation="horizontal"
            >
              <GoARadioItem label="Yes" value="Y" />
              <GoARadioItem label="No" value="N" />
            </GoARadioGroup>
          </GoAFormItem>
        </div>
      )}

      <GoASpacer vSpacing="l" />

      <GoAButtonGroup>
        <GoAButton
          type="tertiary"
          leadingIcon="arrow-back"
          onClick={handlePrevious}
        >
          {getResourceValue("RESOURCES@global.button.previous", "Previous")}
        </GoAButton>

        <GoAButton
          type="primary"
          trailingIcon="arrow-forward"
          testId="next-btn"
          onClick={firstNationStatusForm.handleSubmit}
        >
          {getResourceValue("RESOURCES@global.button.next", "Next")}
        </GoAButton>
      </GoAButtonGroup>

      <GoASpacer vSpacing="l" />
      <Link to=""> Save and finish later</Link>
      <GoASpacer vSpacing="l" />
    </GoAPageBlock>
  );
};

const FirstNationStatusRenderer = (props) => {
  return <FirstNationStatus {...props} />;
};

export default FirstNationStatusRenderer;
