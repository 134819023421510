import { BrowserRouter as Router } from "react-router-dom";
import {
  GoAOneColumnLayout,
  GoAAppHeader,
  GoAAppFooter,
  GoACircularProgress,
} from "@abgov/react-components";
import { reactPlugin } from "@common-payment-portal/common/logging";
import {
  useAppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { SessionProvider } from "@common-payment-portal/lib";
import { AppRouter } from "./AppRouter";
import { useSessionManagement } from "@common-payment-portal/common/apiUtils/useSessionManagement";
import { Header } from "./components/Header";

function App() {
  const { session, isLoading } = useSessionManagement();

  const { portal = {} } = session;

  const appInsightsContext = useAppInsightsContext();

  const handleLogError = (error, errorInfo) => {
    appInsightsContext.trackException({ error, errorInfo });
  };

  return (
    <AppInsightsErrorBoundary
      onError={handleLogError}
      appInsights={reactPlugin}
    >
      <Router>
        <SessionProvider>
          <GoAOneColumnLayout>
            <section slot="header">
              <GoAAppHeader url="/" heading={"Admin"}>
                <a href='/list'>Portals</a>
              </GoAAppHeader>
            </section>
            <section>
              <GoACircularProgress
                variant="fullscreen"
                visible={isLoading}
                message="Loading..."
              />
              <AppRouter />
            </section>
            <section slot="footer">
              <GoAAppFooter />
            </section>
          </GoAOneColumnLayout>
        </SessionProvider>
      </Router>
    </AppInsightsErrorBoundary>
  );
}

export default App;
