import { React, useState } from "react";
import {
  GoAButton,
  GoAPageBlock,
  GoASpacer,
  GoACallout,
  GoADivider,
  GoAModal,
  GoAButtonGroup,
} from "@abgov/react-components";

import { useFormik } from "formik";
import DisplayContent from "../components/DisplayContent";
import { VerificationStatuses } from "@common-payment-portal/common/constants";
import {
  getCurrentUrl,
  getLoginEndPoint,
} from "@common-payment-portal/common/apiUtils/auth";

const VERIFY_ACCOUNT = process.env.REACT_APP_VERIFY_ACCOUNT ?? "https://uat.account.alberta.ca/verify";

const Verification = ({
  type,
  id,
  props,
  stepData,
  canEdit,
  defaults,
  applicationTemplate,
  applicationList,
  onNext = null,
  onPrevious = null,
  portal,
  applicationStatus,
  getResourceValue,
  isLoggedIn,
  user,
}) => {
  if (isLoggedIn && user) {
    const { verificationStatus } = user;

    if (verificationStatus === VerificationStatuses.Verified) {
      onNext({});
    }
  }
  const [showModal, setShowModal] = useState(false);

  const onSignin = () => {
    window.location.href = getLoginEndPoint();
  };

  const closeModel = () => {
    setShowModal(false);
    onNext({});
  };

  return (
    <GoAPageBlock width="704px" data-testId="verification">
      <h1>
        {getResourceValue(
          "RESOURCES@verification.title",
          "Verify your Alberta.ca Account"
        )}
      </h1>

      <GoACallout
        type="information"
        heading={getResourceValue(
          "RESOURCES@verification.callout.title",
          "To receive your evacuation payment by eTransfer, you need to verify your Alberta.ca account."
        )}
        size="medium"
      >
        {getResourceValue(
          "RESOURCES@verification.callout.content",
          "You will need valid Alberta's driver license and identification card for this step."
        )}
      </GoACallout>

      <DisplayContent
        content={getResourceValue(
          "RESOURCES@verification.content",
          `<p>
              After verifying you don't need to wait for your activation code in the mail to continue with your application.
           </p>
           <p>
              <b>Please keep this tab open</b> If you have not redirected back to your application after verifying, try refreshing this tab.
           </p>
          `
        )}
      />
      <GoASpacer vSpacing="l" />
      <GoAButton
        type="primary"
        testId="verifyAcountButton"
        onClick={() => window.open(
          VERIFY_ACCOUNT,
          '_blank'
        )}
        trailingIcon="arrow-forward"
      >
        {getResourceValue(
          "RESOURCES@verification.verifyAccount.button",
          "Verify your account"
        )}
      </GoAButton>

      <GoADivider mt="l" mb="l"></GoADivider>
      <DisplayContent
        content={getResourceValue(
          "RESOURCES@verification.continueWithoutVerify.text",
          `<p>
              If you continue without verifying your account, you'll need to contact Alberta Support Contact Centre to receive your payment.
           </p> `
        )}
      />
      <GoASpacer vSpacing="l" />
      <GoAButton
        type="secondary"
        testId="continueWithoutVerifyButton"
        onClick={() => setShowModal(true)}
      >
        {getResourceValue(
          "RESOURCES@verification.checkElgibility.button",
          "Continue without verification"
        )}
      </GoAButton>

      <GoAModal
        calloutVariant="important"
        heading={getResourceValue(
          "RESOURCES@verification.verifyAccount.callout",
          "Are you sure you want to contiue without verifying your Alberta.ca account"
        )}
        open={showModal}
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton type="secondary" onClick={() => setShowModal(false)}>
              {getResourceValue(
                "RESOURCES@global.button.back",
                "Back"
              )}
            </GoAButton>
            <GoAButton onClick={() => closeModel()}>
              {getResourceValue(
                "RESOURCES@global.button.continue",
                "Continue"
              )}
            </GoAButton>
          </GoAButtonGroup>
        }
      >
        <DisplayContent
          content={getResourceValue(
            "RESOURCES@verification.continueWithoutVerify.text",
            "<p>To receive your payment by e-Transfer you must have verified Alberta.ca account.</br>If you continue without verifying your account, you'll need to contact Alberta Supports Contact Centre to receive your payment.</p>"
          )}
        />
      </GoAModal>

      <GoASpacer vSpacing="l" />
    </GoAPageBlock>
  );
};

const VerificationRenderer = (props) => {
  return <Verification {...props} />;
};

export default VerificationRenderer;
