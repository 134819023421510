const Yup = require("yup");
const { fieldTypes, getDefaultValue, fieldSubTypes, isEditableField } = require("./fieldTypes");
const {
  validationConstants,
  phoneRegExp,
} = require("../validations/constants");

const fileRequiredSchema = Yup.object().shape({
  id: Yup.string(validationConstants.invalid)
    .required(validationConstants.required)
    .required(validationConstants.required),
  name: Yup.string(validationConstants.invalid).required(
    validationConstants.required
  ),
});

const fileSchema = Yup.object().shape({
  id: Yup.string(validationConstants.invalid).required(
    validationConstants.required
  ),
  name: Yup.string(validationConstants.invalid),
});

const isEmpty = (value) => {
  if (!value) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === "object") {
    const keys = Object.keys(value ?? {});
    if (!keys || keys.length === 0) {
      return true;
    }

    for (var i = 0; i < keys.length; i++) {
      const empty = isEmpty(value[keys[i]]);

      if (!empty) {
        return false;
      }
    }

    return true;
  }

  if (!value || value === "" || value.toString().trim() === "") {
    return true;
  }

  return false;
};

const validateValueType = ({ field, value }) => {
  if (isEmpty(value)) {
    return true;
  }

  const { type, subType } = field;

  if (type === fieldTypes.check) {
    return Yup.boolean().isValid(value);
  }

  if (type === fieldTypes.select) {
    return Yup.string().isValid(value);
  }

  if (type === fieldTypes.file) {
    return fileSchema.isValid(value);
  }

  if (subType === fieldSubTypes.date || subType === fieldSubTypes.datetime) {
    return Yup.date().isValid(value);
  }

  if (subType === fieldSubTypes.email) {
    return Yup.string().email().isValid(value);
  }

  if (subType === fieldSubTypes.phone) {
    return Yup.string().matches(phoneRegExp, "").isValid(value);
  }

  if (type === fieldSubTypes.number) {
    return Yup.number().isValid(value);
  }

  return fileSchema.isValid(value);
};

const validateInput = async ({ form, data }) => {

  let validationErrors = {};
  const { submitEnabled } = form.formSettings;
  const { fields } = form;

  if (!submitEnabled) {
    throw new Error("Submit is not allowed for this form");
  }

  for(var i=0;i<fields.length;i++) {
    const field = fields[i];

    if (!isEditableField(field)) {
      return;
    }

    validationErrors = {...validationErrors, ...validateField({form, data, field})};
  }

  return validationErrors;
};

const validateField = ({ form, data, field }) => {
  const errors = {};

  const { validations = {}, type } = field ?? {};

  const fieldValue = data
    ? data[field.name] ?? getDefaultValue(type)
    : getDefaultValue(type);

  const { required = false } = validations;

  if (required && isEmpty(fieldValue)) {
    errors[`${field.name}`] = validationConstants.required;
    return errors;
  }

  if (!validateValueType({ field, value: fieldValue })) {
    errors[field.name] = validationConstants.invalid;
    return errors;
  }

  return errors;
};

module.exports = {
  validateInput,
};
