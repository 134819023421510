const fieldTypes = {
  header: "header",
  paragraph: "paragraph",
  input: "input",
  file: "file",
  check: "check",
  select: "select",
};

const fieldSubTypes = {
  h1: "h1", 
  h2: "h2", 
  h3: "h3", 
  h4: "h4", 
  h5: "h5", 
  h6: "h6",
  label: "label", 
  paragraph: "paragraph",
  text: 'text',
  number: "number",
  email: "email",
  phone: "phone",
  password: "password",
  textarea: "textarea",
  date: "date",
  datetime: "datetime",
  file: "file",
  check: "check",
  radio: "radio", 
  singleSelect: "singleSelect", 
  multiSelect: "multiSelect",
  document: 'document',
  image: 'image',
  document_image: 'document_image',
};

const validFieldSubTypesByType = {
  [fieldTypes.header]: ["h1", "h2", "h3", "h4", "h5", "h6"],
  [fieldTypes.paragraph]: ["label", "paragraph"],
  [fieldTypes.input]: [
    "text",
    "email",
    "phone",
    "password",
    "textarea",
    "date",
    "datetime",
  ],
  [fieldTypes.file]: ["document", "image", "document_image"],
  [fieldTypes.check]: ["check"],
  [fieldTypes.select]: ["radio", "singleSelect", "multiSelect"],
};

const isEditableField = (type) => {
  if (type == fieldTypes.header || type == fieldTypes.paragraph) {
    return false;
  }

  return true;
};

const getDefaultValue = ({type, subType}) => {
  if ( type === fieldTypes.check) {
    return false;
  } if (subType === fieldSubTypes.date || subType === fieldSubTypes.datetime) {
    return null;
  }

  return '';
}

module.exports = {
  fieldTypes,
  fieldSubTypes,
  validFieldSubTypesByType,
  isEditableField,
  getDefaultValue,
};
