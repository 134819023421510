import { createContext, useCallback, useState } from "react";
import { useSessionManagement } from "@common-payment-portal/common/apiUtils/useSessionManagement";

export const SessionContext = createContext(null);

export const SessionProvider = ({children}) => {
    
    const { session, isLoading, clearSession } = useSessionManagement();
    const [title, setTitle] = useState(null);

    const { isLoggedIn, user = {}, portal = {} } = session;
    const { userType } = user ?? {};

    const { header = "Public" } = portal ?? {};

    const appendToTitle = (v) => {
        setTitle(x => `${header}${v ? ` - ${v}` : ''}`)
    }

    const value = {
        isLoggedIn,
        isLoading,
        user,
        portal,
        userType,
        clearSession,
        headerTitle: title ?? header ?? "Public",
        appendToTitle,
    };

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    );
}
